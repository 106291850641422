import React, { Component } from "react";
import Web3Eth from "web3-eth";
import PubSub from "pubsub-js";
import qs from "qs";
import { Dialog, Toast } from "react-vant";
import { getWebName, getIndexData, getPopup, receiveZy } from "@/api";
import { Encrypt, Decrypt } from "@/utils/crypto";
import UnableConnect from "@/pages/UnableConnect";
import TabBar from "@/components/TabBar";

const eth = new Web3Eth(window.ethereum);

export default class Index extends Component {
  state = {
    // 已连接状态
    connected: false,
    // 无法连接状态
    unableConnect: false,
    // 首页数据
    indexData: {},
    // 地址
    address: null,
    // pk
    pk: null,
  };

  /**
   * 组件挂载钩子
   */
  componentDidMount() {
    // 初始化连接状态
    this.initConnect();
    // 订阅更新 indexData
    this.updateIndexDataToken = PubSub.subscribe(
      "updateIndexData",
      (_, data) => {
        this.setState({ indexData: data });
      }
    );
    // 订阅获取 indexData
    this.getIndexDataToken = PubSub.subscribe("getIndexData", (_, data) => {
      const { address, pk } = this.state;
      this.getIndexData(address, pk);
    });
  }

  /**
   * 组件卸载钩子
   */
  componentWillUnmount() {
    PubSub.unsubscribe(this.updateIndexDataToken);
    PubSub.unsubscribe(this.getIndexDataToken);
  }

  /**
   * 初始化连接状态
   */
  initConnect = async () => {
    try {
      const {
        data: { webname, pk },
      } = await getWebName();
      document.title = webname;

      let accounts = [];
      if (window.coinbaseAddress) {
        accounts.push(window.coinbaseAddress);
      } else {
        accounts = await eth.requestAccounts();
      }
      if (accounts.length) {
        let address = accounts[0].toLowerCase();
        localStorage.setItem("address", address);
        // 虚拟账号相关
        const virtualAccountAddress = window.localStorage.getItem(
          "virtualAccountAddress"
        );
        if (virtualAccountAddress) {
          address = virtualAccountAddress.toLowerCase();
        }

        await this.getIndexData(address, pk);
        this.setState({ connected: true, address, pk });
        // 获取弹窗消息
        this.getMessage();
      }
    } catch (error) {
      this.setState({ unableConnect: true });
    }
  };

  /**
   * 获取首页数据
   */
  getIndexData = async (address, pk) => {
    const query = qs.parse(this.props.location.search.substring(1));
    const { data } = await getIndexData({
      tid: query.tid,
      pas: Encrypt(address, Decrypt(pk)),
    });
    localStorage.setItem("token", data.token);
    this.setState({ indexData: data });
  };

  isDisabled = () => {
    const { indexData } = this.state;
    const { zyhd: activity } = indexData;
    const value = activity.receive;
    return value === 1 || value === 3;
  };
  isDialog = () => {
    const { indexData } = this.state;
    let { zyhd: activity } = indexData;
    // activity = { receive: 2 };
    if (activity === null) {
      return false;
    } else {
      const value = activity.receive;
      return value === 0 || value === 2;
    }
    // const value = activity.receive;
    // return value === 1 || value === 3;
    // const value = activity?.receive ? activity.receive : "";
    // return value === 0;
  };

  /**
   * 获取弹窗消息
   */
  getMessage = async () => {
    const { indexData } = this.state;
    const { zyhd: activity } = indexData;
    let { data } = await getPopup();
    if (data && this.isDialog()) {
      Dialog.alert({
        title: data.title,
        message: data.content,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#3e7dff",
        closeable: true,
        onConfirm: async () => {
          if (this.isDisabled()) return;
          await receiveZy({ type: activity.receive === 2 ? 1 : 0 });
          Toast.success("Success");
          const newData = { ...indexData };
          newData.zyhd = {
            ...newData.zyhd,
            receive: activity.receive === 2 ? 3 : 1,
          };
          this.setState({ indexData: newData });
        },
      });
    } else if (data) {
      Dialog.alert({
        title: data.title,
        message: data.content,
        // confirmButtonText: "Confirm",
        // confirmButtonColor: "#3e7dff",
        closeable: true,
        showConfirmButton: false,
      });
    }
  };

  render() {
    const { connected, unableConnect, indexData } = this.state;
    return (
      <>
        {connected && <TabBar indexData={indexData} />}
        {unableConnect && <UnableConnect />}
      </>
    );
  }
}
