import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './App.scss'
import Index from './pages/Index'
import Coinbase from './pages/Coinbase'
import LoansRepayment from './pages/Loans/components/Repayment'

export default class App extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path='/' component={Index} />
                    <Route path='/coinbase' component={Coinbase} />
                    <Route path='/loans/repayment' component={LoansRepayment} />
                </Switch>
            </HashRouter>
        )
    }
}
