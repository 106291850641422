import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Toast } from 'react-vant'

import './index.scss'

export default class UnableConnect extends Component {
  render() {
    return (
      <div className="unable_connect_page">
        <img
          className="top_img"
          src={require('./assets/img_unable_connect.png')}
          alt="Unable connect"
        />
        <div className="content_warp">
          <div className="title">Unable to connect</div>
          <div className="content">This website relies on Ethernet smart contracts to operate, please use a decentralized wallet to access.</div>
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => Toast.info('Copy success')}
          >
            <div className="copy">Copy Link</div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }
}
