import React, { Component } from 'react'
import { Popup } from 'react-vant'
import './index.scss'
import Balance from '../Balance'
import USDTWithdraw from "../USDTWithdraw";
import BankcardWithdraw from '../BankcardWithdraw'
export default class WithdrawPopup extends Component {

  state = {

  }


  /**
   * 弹窗打开触发
   */
   onOpen = () => {

  }


  render() {
    const { visible, onClose, indexData,way } = this.props
    return (
      <Popup
        className='record_popup_container'
        visible={visible}
        onClickOverlay={onClose}
        position='right'
        onOpen={this.onOpen}
      >
        <div className='popup_header'>
          <img
            className='icon_left'
            src={require('./assets/icon_nav_left.svg').default}
            alt='back'
            onClick={onClose}
          />
          <span>Withdraw</span>
        </div>
        <div className='popup_content'>
          <Balance indexData={indexData}/>
          {way===0&&<USDTWithdraw indexData={indexData}/>}
          {way===1&&<BankcardWithdraw onClose={onClose} indexData={indexData}/>}
        </div>
      </Popup>
    )
  }
}
