import React, { Component } from 'react'
import OnlineHelpPopup from '../OnlineHelpPopup'
import LoansPopup from '@/pages/Loans'
import './index.scss'

export default class OnlineHelp extends Component {
    state = {
        // 在线帮助弹窗
        onlineHelpPopup: false,
        loansPopup: false,
        isShowLoans: false,
    }

    changeLoansMenuState = () => {
        this.setState({ isShowLoans: !this.state.isShowLoans })
    }

    handleLoansPopup = () => {
        this.setState({ loansPopup: !this.state.loansPopup })
    }

    /**
     * 显示弹窗
     */
    handleShowPopup = () => {
        this.setState({ onlineHelpPopup: true })
    }

    /**
     * 关闭弹窗
     */
    handleClosePopup = () => {
        this.setState({ onlineHelpPopup: false })
    }

    render() {
        const { onlineHelpPopup, isShowLoans, loansPopup } = this.state
        const { indexData } = this.props
        return (
            <>
                <div>
                    <div className='online_help'>
                        <div className='info' onClick={this.handleShowPopup}>
                            <img className='icon' src={require('./assets/icon_help.svg').default} alt='help' />
                            <span>online customer service</span>
                        </div>
                        <img className='arrow' src={require('./assets/icon_arrow_right.svg').default} alt='arrow' onClick={this.changeLoansMenuState} />
                    </div>

                    {isShowLoans && (
                        <div className='online_help' style={{ marginTop: '10px' }} onClick={this.handleLoansPopup}>
                            <div className='info'>
                                <img className='icon' src={require('./assets/icon_loans.png')} alt='loans' />
                                <span>I want to borrow money</span>
                            </div>
                            {/* <img className='arrow' src={require('./assets/icon_arrow_right.svg').default} alt='arrow' /> */}
                        </div>
                    )}
                </div>
                <LoansPopup visible={loansPopup} indexData={indexData} onClose={this.handleLoansPopup} />
                <OnlineHelpPopup visible={onlineHelpPopup} indexData={indexData} onClose={this.handleClosePopup} />
            </>
        )
    }
}
