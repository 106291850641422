import React, { Component } from "react";
import { Link } from "react-router-dom";
import PubSub from "pubsub-js";
import { Toast } from "react-vant";
import { receiveZy } from "@/api";
import { formatNumberWithCommas } from "@/utils/util.js";
import "./index.scss";

export default class Mortgage extends Component {
  /**
   * 获取按钮文本
   */
  getButtonText = () => {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    switch (activity.receive) {
      case 0:
        return "Apply rewards";
      case 3:
        return "Reward success";
      default:
        return `Reward ${formatNumberWithCommas(
          activity.win_usdt.toFixed(2),
          2
        )} USDT`;
    }
  };

  /**
   * 获取质押数量比率
   */
  getNumRatio = () => {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    const ratio = (activity.zy_usdt / activity.usdt) * 100;
    if (ratio > 100) return 100;
    return parseFloat(ratio.toFixed(2));
  };

  /**
   * 获取周期比率
   */
  getCycleRatio = () => {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    const ratio = (activity.lday / activity.days) * 100;
    if (ratio > 100) return 100;
    return parseFloat(ratio.toFixed(2));
  };

  /**
   * 获取禁用状态
   */
  isDisabled = () => {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    const value = activity.receive;
    return value === 1 || value === 3;
  };
  /**
   * 获取禁用拒绝按钮
   */
  isReject = () => {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    const value = activity.receive;
    return value === 0;
  };
  /**
   * 领取
   */
  receive = async () => {
    if (this.isDisabled()) return;
    const { indexData } = this.props;
    const { zyhd: activity } = indexData;

    await receiveZy({ type: activity.receive === 2 ? 1 : 0 });
    Toast.success("Success");
    const newData = { ...indexData };
    newData.zyhd = { ...newData.zyhd, receive: activity.receive === 2 ? 3 : 1 };
    PubSub.publish("updateIndexData", newData);
  };
  // 拒绝参与活动
  rejectUplan = async () => {
    await receiveZy({ type: -1 });
    const { indexData } = this.props;
    const newData = { ...indexData };
    newData.zyhd = null;
    PubSub.publish("updateIndexData", newData);
    Toast.success("Success");
  };
  render() {
    const {
      indexData: { zyhd: activity },
    } = this.props;
    return (
      <div className="mortgage">
        <div className="title_content">
          <img
            className="icon"
            src={require("./assets/icon_eth.png")}
            alt="eth"
          />
          <div className="right">
            <div className="title">U plan verification</div>
            <div className="sub_title">ERC-20 Beacon Chain</div>
          </div>
        </div>
        <div className="main_content">
          {activity.receive <= 0 && (
            <div className="text_intro">
              The new AI smart contract will open a new contract via Ethernet.
              Apply now and get higher campaign rewards for campaign
              participants once the campaign starts.
            </div>
          )}
          {activity.receive > 0 && (
            <div className="value_list">
              <div className="item">
                <div className="name">Liquidity</div>
                <div className="value">
                  {formatNumberWithCommas(activity.usdt.toFixed(2), 2)} USDT
                </div>
              </div>
              <div className="item">
                <div className="name">Cycle output</div>
                <div className="value">
                  {formatNumberWithCommas(activity.profit.toFixed(2), 2)} USDT
                </div>
              </div>
              <div className="item">
                <div className="name">Contract period</div>
                <div className="value">{activity.days} Day</div>
              </div>
              <div className="item">
                <div className="name">Income ratio</div>
                <div className="value">{activity.profit_fw} %</div>
              </div>
            </div>
          )}
          {activity.receive > 0 && (
            <div className="progress-container">
              <div className="progress-wrap">
                <div className="item">
                  <span style={{ width: `${this.getNumRatio()}%` }}>
                    {this.getNumRatio()}%
                  </span>
                </div>
                <div className="item">
                  <span style={{ width: `${this.getCycleRatio()}%` }}>
                    {this.getCycleRatio()}%
                  </span>
                </div>
              </div>
              <div className="label">
                <div className="item">
                  <span>{activity.zy_usdt}</span>/{activity.usdt}
                </div>
                <div className="item">
                  <span>{activity.lday}day</span>/{activity.days}day
                </div>
              </div>
            </div>
          )}
          {activity.receive === 1 && <div className="status">IN PROGRESS</div>}
          {activity.receive === 2 && <div className="status">AVAILABLE</div>}

          <div
            className={`button ${this.isDisabled() ? "is-disabled" : ""}`}
            onClick={this.receive}
          >
            {this.getButtonText()}
          </div>
          {this.isReject() ? (
            <div className={`button`} onClick={this.rejectUplan}>
              Reject
            </div>
          ) : null}

          <div className="footer_content">
            <div className="item">
              <img
                className="icon"
                src={require("./assets/icon_wallet.png")}
                alt="wallet"
              />
              <span>Added pool liquidity</span>
            </div>
            <div className="item">
              {/* <img
                className='icon'
                src={require('./assets/icon_bank.png')}
                alt='bank'
              /> */}
              <Link to="/coinbase">
                <span>what is Verify Staking</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
