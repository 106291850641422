import React, { Component } from "react";
import { Button, Dialog, Field, Form, Icon, Popup, Toast } from "react-vant";
import "./index.scss";
import { bindBankCard, deleteBankCard, queryBankCard } from "../../../../api";

export default class ManageBankcardPopup extends Component {
  state = {
    lookCardInfo: {},
    isVisible: false,
    bankcardList: [],
  };

  /**
   * 弹窗打开触发
   */
  onOpen = () => {
    this.getBankCardList();
  };

  getBankCardList = async () => {
    const { data } = await queryBankCard();
    data.map((item) => (item.showAccount = false));
    console.log(data);
    this.setState({ bankcardList: data });
  };

  onSubmit = async (formData) => {
    console.log("form", formData);

    if (
      formData.name &&
      formData.banknumber &&
      formData.bankname &&
      formData.country &&
      formData.adds &&
      formData.bankadds &&
      formData.aba &&
      formData.iphone
    ) {
      const res = await bindBankCard(formData);
      Toast.success(res.rpMsg);
      setTimeout(() => {
        this.props.onClose();
      }, 1500);
    } else {
      Toast.fail("Please fill-in \nthis form!");
    }
  };

  onDelete = (item) => {
    console.log("delete", item);
    Dialog.alert({
      title: "Confirm to delete this card?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then(async (r) => {
      await deleteBankCard({ id: item.id });
      this.getBankCardList();
    });
  };

  handleBankcardAccount = (item, index) => {
    console.log(item);
    const { bankcardList } = this.state;
    bankcardList[index].showAccount = !bankcardList[index].showAccount;
    this.setState({
      bankcardList: bankcardList,
      isVisible: true,
      lookCardInfo: item,
    });
  };
  handleCardClose = () => {
    this.setState({ isVisible: false });
  };
  render() {
    const { visible, onClose, indexData } = this.props;
    const { bankcardList, lookCardInfo } = this.state;
    const { isVisible } = this.state;
    return (
      <Popup
        className="record_popup_container"
        visible={visible}
        onClickOverlay={onClose}
        position="right"
        onOpen={this.onOpen}
      >
        <Popup
          visible={isVisible}
          className="cardPopup"
          closeable
          onClickOverlay={this.handleCardClose}
          onClickCloseIcon={this.handleCardClose}
        >
          <div className="lookCardInfo">
            <div>
              <span>Bank Name:</span> {lookCardInfo.bankname}
            </div>
            <div>
              <span>Account Number: </span>
              {lookCardInfo.banknumber}
            </div>
            <div>
              <span>Name:</span> {lookCardInfo.name}
            </div>
            <div>
              <span>Phone Number:</span> {lookCardInfo.iphone}
            </div>
          </div>
        </Popup>
        <div className="popup_header">
          <img
            className="icon_left"
            src={require("./assets/icon_nav_left.svg").default}
            alt="back"
            onClick={onClose}
          />
          <span>Manage Bank card</span>
        </div>
        <div className="popup_content">
          <div className="bankcard-list">
            <div className="title">Bank card List</div>
            {bankcardList.map((item, index) => {
              return (
                <div className="item" key={item.id}>
                  <div className="info">
                    <img src={require("./assets/card.png")} alt="" />
                    <div>
                      {`${item.banknumber.slice(
                        0,
                        4
                      )}**** ****${item.banknumber.slice(-4)}`}
                    </div>
                    {/* {item.showAccount&&(
                                                <>
                                                    <div>Name: {item.name}</div>
                                                    <div>Phone: {item.iphone}</div>
                                                    <div>Bank name: {item.bankname}</div>
                                                </>
                                            )} */}
                  </div>
                  <div className="icons">
                    <Icon
                      className="eye"
                      onClick={() => this.handleBankcardAccount(item, index)}
                      name={item.showAccount ? "eye-o" : "eye-o"}
                    />
                    <Icon name="close" onClick={() => this.onDelete(item)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="bind-bankcard-form">
            <div className="title">Add Bank card</div>
            <Form
              onFinish={this.onSubmit}
              footer={
                <div style={{ margin: "16px 16px 0" }}>
                  <Button round nativeType="submit" type="primary" block>
                    Add
                  </Button>
                </div>
              }
            >
              <Form.Item required name="name" label="Account Name">
                <Field placeholder="Please input your account name" />
              </Form.Item>
              <Form.Item required name="banknumber" label="Account Number">
                <Field placeholder="Please input account number" />
              </Form.Item>
              <Form.Item required name="bankname" label="Bank Name">
                <Field placeholder="Please enter the bank name" />
              </Form.Item>
              <Form.Item required name="country" label="Payee country">
                <Field placeholder="Please enter the payee country" />
              </Form.Item>
              <Form.Item required name="adds" label="Beneficiary Address">
                <Field placeholder="Please enter the beneficiary address" />
              </Form.Item>
              <Form.Item required name="bankadds" label="Bank Address">
                <Field placeholder="Please enter the bank  address" />
              </Form.Item>
              <Form.Item required name="aba" label="ABA Routing Number">
                <Field placeholder="Please enter the ABA Routing Number" />
              </Form.Item>
              <Form.Item required name="iphone" label="Phone Number">
                <Field placeholder="Please enter the Phone Number" />
              </Form.Item>
              {/* not required  */}
              <Form.Item name="swift" label="Swift/BAIN Code">
                <Field placeholder="Please enter the Swift/BAIN Code" />
              </Form.Item>
              {/* <Form.Item name="Liquidation" label="Good Liquidation">
                <Field placeholder="Please enter Good Liquidation" />
              </Form.Item> */}
            </Form>
          </div>
        </div>
      </Popup>
    );
  }
}
