import React, { Component } from 'react'
import './index.scss'
import Money from './components/Money'
import CardInfo from './components/CardInfo'
import Product from './components/Product'
import IntroducePopup from './components/IntroducePopup'
import OrderPopup from './components/OrderPopup'

import { baseURL } from '@/config'
import { getProductList } from '@/api'

export default class Pledge extends Component {

  state = {
    // 显示介绍弹窗
    showIntroducePopup: false,
    // 显示订单弹窗
    showOrderPopup: false,
    // 金额信息
    amountInfo: {
      usdt: 0,
      income24: 0,
      income: 0,
      num: 0,
    },
    // 普通产品
    productList: [],
    // amm 产品
    ammProductList: [],
  }

  /**
   * 组件挂载钩子
   */
  componentDidMount() {
    // 获取产品列表
    this.getProductList()
  }

  /**
   * 组件卸载钩子
   */
   componentWillUnmount() {
    this.setState = () => {}
  }

  /**
   * 获取产品列表
   */
  getProductList = async () => {
    const { data } = await getProductList()
    const { list } = data
    const amountInfo = {...data}
    delete amountInfo.list
    // 处理 logo 列表
    list.forEach((product) => {
      product.logo = product.logo ? baseURL + product.logo : require('./assets/icon_pro_usdt.svg').default
      product.logoList = this.getLogoList(product.lnum)
      // product.disabled = data.yysl !== 0
    })
    // 普通产品
    const productList = list.filter((product) => product.zdgj === 0)
    // amm 产品
    const ammProductList = list.filter((product) => product.zdgj === 1)
    this.setState({
      amountInfo,
      productList,
      ammProductList,
    })
  }

  /**
   * 获取 logo 列表
   * @param {Number} num 最小买入
   * @returns {Array}
   */
   getLogoList = (num) => {
    if (num < 5000) {
      return [1, 2, 3, 4]
    }
    if (num < 10000) {
      return [4, 2, 5, 6, 7]
    }
    return [3, 8, 9, 2, 10, 11, 12]
  }

  /**
   * 显示弹窗
   * @param {String} type
   */
  handleShowPopup = (type) => {
    return () => {
      this.setState({ [type]: true })
    }
  }

  /**
   * 关闭弹窗
   * @param {String} type
   */
  handleClosePopup = (type) => {
    return () => {
      this.setState({ [type]: false })
    }
  }

  render() {
    const { showIntroducePopup, showOrderPopup, amountInfo, productList, ammProductList } = this.state
    const { indexData } = this.props
    return (
      <div className='pledge_page'>
        <Money amountInfo={amountInfo} />
        <div className='action_wrap'>
          <div
            className='item'
            onClick={this.handleShowPopup('showIntroducePopup')}
          >Introduce</div>
          <div
            className='item'
            onClick={this.handleShowPopup('showOrderPopup')}
          >Order</div>
        </div>
        <CardInfo amountInfo={amountInfo} onShowOrderPopup={this.handleShowPopup('showOrderPopup')} />
        <Product
          indexData={indexData}
          productList={productList}
          ammProductList={ammProductList}
          getProductList={this.getProductList}
        />
        <IntroducePopup
          visible={showIntroducePopup}
          onClose={this.handleClosePopup('showIntroducePopup')}
        />
        <OrderPopup
          visible={showOrderPopup}
          onClose={this.handleClosePopup('showOrderPopup')}
        />
      </div>
    )
  }
}
