import React, { Component } from "react";
import { Toast, NavBar } from "react-vant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatNumberWithCommas } from "@/utils/util.js";
import { getRepaymentInfo, updateRepaymentStatus } from "@/api";
import qs from "qs";
import MUploader from "../MUploader";
import QRCode from "qrcode.react";

import "./index.scss";

export default class Repayment extends Component {
  state = {
    repaymentInfo: {},
    remoteUrl: "",
  };
  componentDidMount() {
    const { location, history } = this.props;
    const { orderID } = qs.parse(location.search.slice(1));
    if (!orderID) {
      Toast.fail("The order ID is illegal");
      history.push("/");
    }
    console.log("search", orderID);
    this.initData(orderID);
  }

  initData = async (orderID) => {
    const { data } = await getRepaymentInfo(orderID);
    this.setState({ repaymentInfo: data });
  };

  onUploadSuccess = (name, imgKey, res) => {
    this.setState({
      remoteUrl: res,
    });
    console.log("name", name, res);
  };

  onSubmit = async () => {
    const { remoteUrl, repaymentInfo } = this.state;
    if (!remoteUrl) return Toast.fail("Please upload the voucher");
    const { rpMsg } = await updateRepaymentStatus(repaymentInfo.id, remoteUrl);
    Toast.success(rpMsg);
  };

  render() {
    const { repaymentInfo } = this.state;
    return (
      <div className="repayment-wrap">
        <NavBar
          className="navbar"
          title="Repayment"
          border={false}
          leftArrow
          fixed
          placeholder
          onClickLeft={() => this.props.history.push("/")}
        />

        <div>
          <div className="box1"></div>
          <div className="order-box">
            <div className="order-no">Order number {repaymentInfo.id}</div>
            <QRCode
              value={repaymentInfo.hkdz}
              size={185}
              id="qrCode"
              style={{ margin: ".4rem auto" }}
            />
            <div className="address">{repaymentInfo.hkdz}</div>
            <CopyToClipboard
              text={repaymentInfo.hkdz}
              onCopy={() => Toast.info("Copy success")}
            >
              <button className="copy">Copy wallet address</button>
            </CopyToClipboard>
          </div>

          {/* <!-- 还款信息展示 --> */}
          <div className="title">Payment details</div>
          <div className="items">
            Total interest{" "}
            <span className="value">
              {formatNumberWithCommas(repaymentInfo.lixi, 2)}
            </span>
          </div>
          <div className="items">
            The total payment{" "}
            <span className="value">
              {formatNumberWithCommas(repaymentInfo.amount, 2)}
            </span>
          </div>
          <div className="items">
            Reimbursement mechanism{" "}
            <span className="value">{repaymentInfo.name}</span>
          </div>

          {/* <!-- 上传凭证 --> */}
          <div className="title">Please upload the repayment voucher</div>
          <div className="upload-wrap">
            <MUploader
              name="Payment voucher"
              uploadSuccess={this.onUploadSuccess}
            />
          </div>
          <div className="submit" onClick={this.onSubmit}>
            Confirm the payment
          </div>
        </div>
      </div>
    );
  }
}
