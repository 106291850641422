import React, { Component } from "react";
import { ImagePreview } from "react-vant";
import "./index.scss";

export default class Question extends Component {
  render() {
    const { helpList } = this.props;
    return (
      !!helpList.length && (
        <div className="question">
          {helpList.map((item, index) => {
            return (
              <div key={index} className="item">
                <div className="title">
                  <img
                    className="icon"
                    src={require("./assets/icon_question.svg").default}
                    alt="question"
                  />
                  {item.contens ? (
                    <span>{item.title}</span>
                  ) : (
                    <div>
                      <span>{item.title || " "}</span>
                      <br /> <span>{item.title2 || " "}</span>
                    </div>
                  )}
                </div>
                {item.contens ? (
                  <div className="content">{item.contens}</div>
                ) : (
                  <img
                    className="content"
                    style={{ width: "100%" }}
                    alt=""
                    src={item.img}
                    onClick={() =>
                      ImagePreview.open({
                        images: item.img,
                      })
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      )
    );
  }
}
