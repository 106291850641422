import React, { Component } from 'react'
import './index.scss'

export default class Coinbase extends Component {
  render() {
    return (
      <div className='coinbase_page'>
        <div className='header-nav'>
          <img
            className='logo'
            src={require('./assets/coinbase_logo.png')}
            alt=''
          />
        </div>
        <img
          className='img1'
          src={require('./assets/coinbase_img1.png')}
          alt=''
        />
        <div className='title'>What is staking?</div>
        <p className='desc'>
          Like many things about crypto assets, staking can be a complex idea or a simple idea, depending on how deeply you want to understand. For many traders and investors, it is important to understand that staking is a way to earn rewards by holding certain crypto assets . But even if you're just trying to win a staking reward, it's helpful to have at least a little understanding of how staking works and why it works as it does now.
        </p>
        <img
          className='img2'
          src={require('./assets/coinbase_img2.png')}
          alt=''
        />
        <div className='title'>How does staking work?</div>
        <p className='desc'>
          If you can stake your crypto assets (current options include Tezos, Cosmos, and Ethereum, which is now being upgraded to ETH2 ), you can 'offer' some of your assets and give them time. You can earn rewards according to the scale over time . Offering of assets held is usually done through a 'staking pool', which is considered to be similar to a savings account with interest.<br />
          The reason you can stake your crypto assets and earn rewards is because the blockchain uses those assets. Cryptocurrency assets that can be stakes use a 'consensus building mechanism' called Proof of Stake (PoS). PoS is a way to ensure that all transactions are validated and protected without the intervention of a bank or payment processor. If you choose to stake a crypto asset you own, that asset will be used as part of such a process.
        </p>
        <p className='tips'>
          (Get all the information you need to understand the transition to ETH2 in our guide to all of Ethereum.)
        </p>
        <img
          className='img3'
          src={require('./assets/coinbase_img3.png')}
          alt=''
        />
        <div className='title'>Why are only some crypto assets staking?</div>
        <p className='desc'>
          From here on, the content will be more specialized. For example, Bitcoin cannot be used for staking. You need to know the background to understand why.
        </p>
        <p className='desc'>
          Cryptocurrency assets are usually decentralized. In other words, there is no central authority to operate the crypto assets. So how do all the computers in a decentralized network get to the right answer without a central authority like a bank or credit card company that tells you the right answer? Those computers use a 'consensus building mechanism'.
        </p>
        <p className='desc'>
          Many crypto assets, including Bitcoin and Ethereum 1.0, use a consensus building mechanism called Proof of Work (PoW). The network goes through PoW and invests a huge amount of processing power to solve the problem. Issues such as approving transactions between strangers on the other side of the globe and making sure no one is spending the same money twice. Part of that process involves 'minors' from around the world, who compete to solve crypto puzzles first. The winner gets the right to add the latest 'block' of the validated transaction onto the blockchain. And you will receive some crypto assets as a reward.
        </p>
        <p className='desc'>
          PoW is an extensible solution for relatively uncomplicated blockchains, such as the Bitcoin blockchain (which records incoming and outgoing transactions like a bank ledger). However, the block chain on deployed in distributed financial (Defi) including the global, intended more complex, such as ethereum where the application resides wide variety possible for the activity is too much PoW causes failure There is sex. As a result, transactions can take longer to process and fees can increase.
        </p>
        <img
          className='img4'
          src={require('./assets/coinbase_img4.png')}
          alt=''
        />
        <div className='title'>What is Proof of Stake (PoS)?</div>
        <p className='desc'>
          A new 'consensus building mechanism' called Proof of Stake (PoS) has emerged with the aim of increasing processing speed and efficiency while reducing fees. PoS reduces costs primarily by eliminating the need for many miners to solve math problems, which is an energy-intensive process. Instead, those who are just investing in that blockchain through staking approve the transaction.
        </p>
        <p className='desc'>
          Staking performs a similar function to mining in that it is the process of selecting network participants, adding the latest batch of transactions to the blockchain, and earning some crypto assets in return.
        </p>
        <p className='desc'>
          The exact implementation will vary from project to project, but basically users endanger their tokens and seek the opportunity to add new blocks to the blockchain and earn rewards in return. The tokens they stake serve as a guarantee of the legitimacy of new transactions added to the blockchain.
        </p>
        <p className='desc'>
          The network chooses validators (as is usually known) based on the size of the participants' staking and the length of the token holdings they are staking. Therefore, the most invested participants will be rewarded. On the other hand, if a new block transaction turns out to be invalid, the user may be fined a certain amount of the staking tokens on the network. The collection of such fines is known as a thrashing event.
        </p>
        <img
          className='img5'
          src={require('./assets/coinbase_img5.png')}
          alt=''
        />
        <div className='title'>What are the benefits of staking?</div>
        <p className='desc'>
          Many long-term crypto asset holders are looking at staking as a way to make better use of their assets by earning rewards rather than simply leaving them as fertilizers in their wallets. ..
        </p>
        <p className='desc'>
          Staking also has the added benefit of contributing to the safety and efficiency of the blockchain projects it supports. By staking a portion of an asset, you can increase its blockchain's attack resistance and transaction processing power. (Some projects give staking participants a 'governance token,' and holding a governance token gives you a say in future changes and upgrades to that protocol.)
        </p>
        <img
          className='img6'
          src={require('./assets/coinbase_img6.png')}
          alt=''
        />
        <div className='title'>What are the risks of staking?</div>
        <p className='desc'>
          Staking often requires a lockup or 'vesting' period that prevents crypto assets from being issued over a period of time. During that period, even if prices fluctuate, you will not be able to trade staking tokens, which can be a drawback. Therefore, before staking, it is important to find out the specific staking requirements and rules for each project you are trying to participate in.
        </p>
        <img
          className='img7'
          src={require('./assets/coinbase_img7.png')}
          alt=''
        />
        <div className='title'>How do you get started with staking?</div>
        <p className='desc'>
          Staking is often open to anyone who wants to participate. However, a complete validator may require at least a significant investment (eg at least 32 ETH for ETH2), as well as specialized knowledge and a dedicated computer to perform approval work day and night with no downtime. I have. In addition, validators may be fined for staking crypto assets due to downtime, so participation at this level requires careful attention to security, which is a heavy burden.
        </p>
        <p className='desc'>
          However, for most participants there is an easier way to get involved. Through an exchange like Coinbase, you can stock up in the staking pool the quantity you can afford. This method lowers the barriers to participation and allows investors to start earning rewards without having to run the validator hardware themselves. At Coinbase, staking is available to most customers in the United States and many non-US countries.
        </p>
      </div>
    )
  }
}
