import React, { Component } from "react";
import moment from "moment-timezone";
import { Popup, Empty } from "react-vant";
import { getLoansHistory } from "@/api";
import { formatNumberWithCommas } from "@/utils/util.js";
import "./index.scss";

export default class RecordPopup extends Component {
  state = {
    // 记录列表
    recordList: [],
    repaymentUrl: "",
  };

  // 获取最新数据
  getLatestData = async () => {
    const {
      data: { list, url },
    } = await getLoansHistory();
    this.setState({ recordList: list, repaymentUrl: url });
  };

  /**
   * 弹窗打开触发
   */
  onOpen = () => {
    // 获取提现记录
    this.getLatestData();
  };

  /**
   * 日期格式化
   */
  dateFormat = (value) => {
    const tz = moment.tz.guess();
    return moment(value * 1000)
      .tz(tz)
      .format("YYYY-MM-DD HH:mm:ss");
  };

  /**
   * 状态格式化
   */
  statusFormat = (value) => {
    // 0待审核，1已放款，2已取消（拒绝放款），3还款中，4已还款，5已逾期
    // const types = {
    //     0: '待审核',
    //     1: '已放款',
    //     2: '已取消',
    //     3: '还款中',
    //     4: '已还款',
    //     5: '已逾',
    // }
    const types = {
      0: "Pending review",
      1: "Disbursed",
      2: "Canceled",
      3: "Repayment in progress",
      4: "Repaid",
      5: "Overdue",
    };
    return types[value] || "---";
  };

  toRepayment = (id) => {
    const url = `${this.state.repaymentUrl}/?orderID=${id}`;
    console.log("url", url);
    // return
    window.location.href = url;
  };

  render() {
    const { recordList } = this.state;
    const { visible, onClose } = this.props;
    return (
      <Popup
        className="record_loans_popup_container"
        visible={visible}
        onClickOverlay={onClose}
        position="right"
        onOpen={this.onOpen}
      >
        <div className="popup_header">
          <img
            className="icon_left"
            src={require("./assets/icon_nav_left.svg").default}
            alt="back"
            onClick={onClose}
          />
          <span></span>
        </div>
        <div className="popup_content">
          <div className="record_content">
            {recordList.length ? (
              recordList.map((record) => {
                return (
                  <div className="record" key={record.id}>
                    <div className="item">
                      <div>
                        <span className="u-m-r-10">Loan Contract No</span>
                        <span>{record.id}</span>
                      </div>
                      <div className="status">
                        {this.statusFormat(record.state)}
                      </div>
                    </div>
                    <div className="item grey">
                      <div>
                        <span style={{ marginRight: ".1rem" }}>
                          Application date
                        </span>
                        <span>{this.dateFormat(record.ctime)}</span>
                      </div>
                    </div>
                    <div className="tags">
                      <div className="tag">cycle{record.days}</div>

                      {record.state === 1 && (
                        <div
                          className="tag red"
                          onClick={() => this.toRepayment(record.id)}
                        >
                          To reimbursement
                        </div>
                      )}
                    </div>
                    <div className="item">
                      <div className="desc">
                        <span className="name " style={{ textAlign: "left" }}>
                          Monetary amount
                        </span>
                        <span className="number">
                          {formatNumberWithCommas(record.amount, 2)} USDT
                        </span>
                      </div>
                      <div className="desc" style={{ textAlign: "center" }}>
                        <span className="name">interest</span>
                        <span className="number">
                          {formatNumberWithCommas(record.lixi, 2)} USDT
                        </span>
                      </div>
                      <div className="desc" style={{ textAlign: "right" }}>
                        <span className="name">Total payments</span>
                        <span className="number">
                          {formatNumberWithCommas(
                            record.amount + record.lixi,
                            2
                          )}{" "}
                          USDT
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Empty
                description="No data"
                style={{ "--rv-empty-description-color": "#333" }}
              />
            )}
          </div>
        </div>
      </Popup>
    );
  }
}
