import React, { Component } from 'react'
import { Popup } from 'react-vant'
import './index.scss'

export default class AmmIntroducePopup extends Component {
  render() {
    const { visible, onClose } = this.props;
    return (
      <Popup
        className='amm_introduce_popup_container'
        visible={visible}
        onClickOverlay={onClose}
        position='right'
      >
        <div className='popup_header'>
          <img
            className='left_back'
            src={require('./assets/icon_nav_left.svg').default}
            alt='back'
            onClick={onClose}
          />
          <span>Auto Staking</span>
        </div>
        <div className='content_wrap'>
          <div className='intro_detail'>
            <div className='item'>Crypto staking is the process of locking up crypto holdings in order to obtain rewards or earn higher interest. Cryptocurrencies are built with the blockchain technology, in which the crypto transactions are verified, and the resulting data is stored on the blockchain. Staking is another way to describe validating those transactions on a blockchain. </div>
            <div className='item'>It's all part and parcel of a consensus mechanism called “proof of stake.”Only cryptocurrencies operating on a proof-of-stake model allow staking. There's no staking involved in proof-of-work cryptocurrencies, such as Bitcoin.</div>
            <div className='item'>In this approach, the exchange does much of the administrative work for you, seeking out a node for you to join so you don’t have to do it yourself. The proof-of-stake cryptocurrencies have lock-up periods, which means you won’t be able to access your crypto for a certain amount of time.</div>
            <div className='item'>It's time to own your rewards from being the blocks of a proof-of-stake blockchain.</div>
          </div>
        </div>
      </Popup>
    )
  }
}
