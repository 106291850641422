import React, { Component } from 'react'
import './index.scss'
import BuyPopup from '../BuyPopup'
import ProductItem from '../ProductItem'
import AmmIntroducePopup from '../AmmIntroducePopup'

export default class Product extends Component {

  state = {
    // 显示购买弹窗
    showBuyPopup: false,
    // 当前购买产品
    currentProduct: null,
    // 显示 AMM 产品介绍
    showAmmIntroducePopup: false,
  }

  /**
   * 显示弹窗
   */
  handleShowPopup = (product) => {
    return () => {
      if (product.disabled) return
      this.setState({
        showBuyPopup: true,
        currentProduct: product,
      })
    }
  }

  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    this.setState({ showBuyPopup: false })
  }

  /**
   * 显示 AMM 介绍弹窗
   */
  handleShowAmmPopup = () => {
    this.setState({ showAmmIntroducePopup: true })
  }

  /**
   * 关闭 AMM 介绍弹窗
   */
  handleCloseAmmPopup = () => {
    this.setState({ showAmmIntroducePopup: false })
  }

  render() {
    const { showBuyPopup, currentProduct, showAmmIntroducePopup } = this.state
    const { indexData, productList, ammProductList, getProductList } = this.props
    return (
      <>
        {
          !!productList.length &&
          <div className='product_wrap'>
            <div className='title'>Strategic product</div>
            <div className='product_list'>
              {
                productList.map((product) => {
                  return (
                    <ProductItem
                      key={product.id}
                      product={product}
                      onBuy={this.handleShowPopup}
                    />
                  )
                })
              }
            </div>
          </div>
        }
        {
          !!ammProductList.length &&
          <div className='product_wrap'>
            <div className='title' onClick={this.handleShowAmmPopup}>
              <span>Auto Staking</span>
              <img
                className='icon_doubt'
                src={require('./assets/icon_doubt.svg').default}
                alt='doubt'
              />
            </div>
            <div className='product_list'>
              {
                ammProductList.map((product) => {
                  return (
                    <ProductItem
                      key={product.id}
                      product={product}
                      onBuy={this.handleShowPopup}
                    />
                  )
                })
              }
            </div>
          </div>
        }
        {
          !!currentProduct &&
          <BuyPopup
            visible={showBuyPopup}
            indexData={indexData}
            product={currentProduct}
            onClose={this.handleClosePopup}
            getProductList={getProductList}
          />
        }
        <AmmIntroducePopup
          visible={showAmmIntroducePopup}
          onClose={this.handleCloseAmmPopup}
        />
      </>
    )
  }
}
