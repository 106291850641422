import React, { Component } from "react";
import PubSub from "pubsub-js";
import { Dialog, Toast } from "react-vant";
import { withdrawal, queryBankCard } from "@/api";
import "./index.scss";
import ManageBankcardPopup from "../ManageBankcardPopup";
import AddPasswordPopup from "../addPassowrdPopup";
export default class BankcardWithdraw extends Component {
  state = {
    // 提现金额
    withdrawAmount: "",
    // 加入弹窗
    showPasswordPopup: false,
    showManageBankcardPopup: false,
    showBindBankcardDialog: false,
    bankcardList: [],
    form: {
      bid: null,
      tbType: 1,
      num: null,
    },
    currentSelectedCard: null,
  };
  getBankCardList = async () => {
    const { data } = await queryBankCard();

    data.sort((a, b) => b.id - a.id);
    console.log(data, "data");
    this.setState({ bankcardList: data });
    if (data.length === 0) {
      this.setState({ showBindBankcardDialog: true });
    } else {
      const { form } = this.state;
      form.bid = data[0].id;
      this.setState({ currentSelectedCard: data[0] });
      this.setState({ form: form });
    }
  };
  /**
   * 显示弹窗
   */
  handleShowPopup = () => {
    this.setState({ showPasswordPopup: true });
  };
  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    this.setState({ showPasswordPopup: false });
  };

  passwordWithdraw = async (code) => {
    const { withdrawAmount, form, currencyList } = this.state;
    const { indexData } = this.props;
    // console.log(code, "拿到密码了");
    if (indexData.zjd === 0) {
      PubSub.publish("updateIndexData", { ...indexData, zjd: 1 });
    }
    form.num = withdrawAmount;
    const { data } = await withdrawal({ ...form, code });
    Toast.success("Success");
    this.setState({ withdrawAmount: "" });
    PubSub.publish("updateIndexData", {
      ...indexData,
      usdt: data.usdt,
      zjd: 1,
    });
  };
  async componentDidMount() {
    this.getBankCardList();
  }

  /**
   * 提现金额改变
   */
  handleWithdrawAmountChange = (event) => {
    this.setState({ withdrawAmount: event.target.value });
  };

  /**
   * 提现金额失去焦点
   */
  handleWithdrawAmountBlur = (event) => {
    let { value } = event.target;
    value = parseFloat(value);
    value = isNaN(value) ? "" : parseFloat(value.toFixed(2));
    event.target.value = value;
    this.setState({ withdrawAmount: value });
  };

  /**
   * 最大金额
   */
  handleMax = async () => {
    const { indexData } = this.props;
    this.setState({ withdrawAmount: indexData.usdt });
  };

  /**
   * 提现
   */
  handleWithdraw = async () => {
    const { withdrawAmount } = this.state;
    const { indexData } = this.props;
    if (!withdrawAmount || withdrawAmount <= 0)
      return Toast.info("Please input the correct data");
    if (withdrawAmount < indexData.winmin)
      return Toast.info(`Minimum withdraw ${indexData.winmin}`);
    return this.handleShowPopup();
    // const { form } = this.state;
    // form.num = withdrawAmount;
    // const { data } = await withdrawal(form);
    // Toast.success("Success");
    // this.setState({ withdrawAmount: "" });
    // PubSub.publish("updateIndexData", { ...indexData, usdt: data.usdt });
  };

  openManageBankcardPopup = () => {
    this.setState({ showManageBankcardPopup: true });
    this.setState({ showBindBankcardDialog: false });
  };

  closeManageBankcardPopup = () => {
    this.setState({ showManageBankcardPopup: false });
    this.getBankCardList();
  };

  closeDialog = () => {
    console.log("close");
    this.setState({ showBindBankcardDialog: false });
    this.props.onClose();
  };

  //设置所选银行卡
  handleSelectChange = (e) => {
    const { form, bankcardList } = this.state;
    form.bid = e.target.value;
    const currentSelectedCard = bankcardList.filter(
      (item) => item.id == e.target.value
    )[0];
    console.log(bankcardList, currentSelectedCard);
    this.setState({ form: form, currentSelectedCard: currentSelectedCard });
  };
  //选择银行卡
  setTabIndex = (item, index) => {
    // const {index} = this.state
    const { form } = this.state;
    form.bid = item.id;
    this.setState({ form: form, currentSelectedCard: item });
  };
  // handle

  render() {
    const {
      withdrawAmount,
      showManageBankcardPopup,
      bankcardList,
      currentSelectedCard,
      showPasswordPopup,
    } = this.state;
    const { indexData } = this.props;
    return (
      <>
        <div className="withdraw_wrap">
          <div className="title">
            <img src={require("../SelectWay/assets/bank.png")} />
            <span>Bankcard withdrawal</span>
            <span
              className="bind-bankcard"
              onClick={this.openManageBankcardPopup}
            >
              Bind bank card
            </span>
          </div>
          <div className="bankcard-list">
            {bankcardList.map((item, index) => {
              return (
                <div
                  className={`item ${
                    item.id === this.state.form.bid ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => this.setTabIndex(item, index)}
                >
                  <div className="leftName">
                    <img className="icon" src={require("./assets/card.png")} />
                    <span>{item.name}</span>
                  </div>
                  {item.id === this.state.form.bid && (
                    <img src={require("./assets/ratio.png")} />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form">
            {currentSelectedCard && (
              <>
                <div className="item">
                  <div className="key">Cardholder's Name</div>
                  <div className="value">{currentSelectedCard.name}</div>
                </div>
                <div className="item">
                  <div className="key">Bank card number</div>
                  <div className="value">{currentSelectedCard.banknumber}</div>
                </div>
              </>
            )}
          </div>
          <div className="title">Withdrawal Amount</div>
          <div className="input_wrap">
            <input
              className="input"
              type="number"
              placeholder="0.0"
              value={withdrawAmount}
              onChange={this.handleWithdrawAmountChange}
              onBlur={this.handleWithdrawAmountBlur}
            />
            <div className="max" onClick={this.handleMax}>
              MAX
            </div>
          </div>
          <p className="cash_tips">{indexData.winminTxt}</p>
          <div className="submit_btn" onClick={this.handleWithdraw}>
            WITHDRAW
          </div>
        </div>
        {showManageBankcardPopup && (
          <ManageBankcardPopup
            indexData={indexData}
            visible={showManageBankcardPopup}
            onClose={this.closeManageBankcardPopup}
          />
        )}
        {this.state.showBindBankcardDialog && (
          <Dialog
            visible={this.state.showBindBankcardDialog}
            confirmButtonText={"Confirm"}
            showCancelButton
            cancelButtonText={"Cancel"}
            onConfirm={this.openManageBankcardPopup}
            onCancel={this.closeDialog}
          >
            <div style={{ padding: "15px", textAlign: "center" }}>
              You have not bound a bank card yet, please add a bank card first
            </div>
          </Dialog>
        )}
        <AddPasswordPopup
          visible={showPasswordPopup}
          indexData={indexData}
          onClose={this.handleClosePopup}
          onWithdraw={this.passwordWithdraw}
        />
      </>
    );
  }
}
