import React, { Component } from 'react'
import { getNodeData } from '@/api'
import './index.scss'

import TotalData from './components/TotalData'
import JoinInfo from './components/JoinInfo'
import MarketList from './components/MarketList'
import OfficialWebsite from './components/OfficialWebsite'
import Partner from './components/Partner'
import Mortgage from './components/Mortgage'

export default class Home extends Component {
  state = {
    // 节点数据
    nodeData: {},
  }

  /**
   * 组件挂载钩子
   */
   componentDidMount() {
    // 获取节点数据
    this.getNodeData()
  }

  /**
   * 组件将卸载钩子
   */
  componentWillUnmount() {
    clearTimeout(this.timer)
    this.setState = () => {}
  }

  /**
   * 获取节点数据
   */
  getNodeData = async () => {
    this.timer = setTimeout(() => {
      this.getNodeData()
    }, 5000)
    const { data } = await getNodeData()
    this.setState({ nodeData: data })
  }

  render() {
    const { indexData } = this.props
    const { nodeData } = this.state
    return (
      <div className='home_page'>
        <img
          className='banner'
          src={require('./assets/img_banner.png')}
          alt='banner'
        />
        <TotalData indexData={indexData} />
        {indexData.zyhd && <Mortgage indexData={indexData} />}
        <JoinInfo indexData={indexData} nodeData={nodeData} />
        <MarketList nodeData={nodeData} />
        <OfficialWebsite />
        <Partner />
        <div className='bottom_over'>It's over</div>
      </div>
    )
  }
}
