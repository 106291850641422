import React, { Component } from "react";
import { wzList } from "@/api";
import "./index.scss";

import OnlineHelp from "./components/OnlineHelp";
import Question from "./components/Question";

export default class Help extends Component {
  state = {
    // 帮助列表
    helpList: [],
  };

  /**
   * 组件挂载钩子
   */
  componentDidMount() {
    // 获取帮助列表
    this.getHelpList();
  }

  /**
   * 组件卸载钩子
   */
  componentWillUnmount() {
    this.setState = () => {};
  }

  /**
   * 获取帮助列表
   */
  getHelpList = async () => {
    const { data } = await wzList();
    const imgList = [
      {
        title: "NMLS Regulated",
        title2: "MTL License",
        img: [require("./components/Question/assets/MTL.jpg")],
      },
      {
        title: `SEC Regulated`,
        title2: "Common Financial Service License",
        img: [require("./components/Question/assets/SEC.jpg")],
      },
      {
        title: "NYSDFS Regulated",
        title2: "Digital Currency License",
        img: [require("./components/Question/assets/NYSDFS.jpg")],
      },
    ];
    // console.log(data, [...data, ...imgList]);
    this.setState({ helpList: [...data, ...imgList] });
  };

  render() {
    const { helpList } = this.state;
    const { indexData } = this.props;
    return (
      <div className="help_page">
        <img
          className="banner"
          src={require("./assets/img_banner.png")}
          alt="banner"
        />
        <OnlineHelp indexData={indexData} />
        <Question helpList={helpList} />
      </div>
    );
  }
}
