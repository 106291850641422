import React, { Component } from 'react'
import './index.scss'
import {formatNumberWithCommas} from "../../../../utils/util";

export default class Money extends Component {
  render() {
    const { amountInfo } = this.props
    return (
      <div className='money_wrap'>
        <span>$</span>
        <span className='value'> {formatNumberWithCommas(amountInfo.usdt.toFixed(2),2)}</span>
        <div className='info'>Funds in Escrow (USDT)</div>
      </div>
    )
  }
}
