import CryptoJS from 'crypto-js';

const defaultKey = CryptoJS.enc.Utf8.parse('bkSSjs98301dghfs');

/**
 * 加密
 * @param {String} word
 * @param {String} key
 * @returns {String}
 */
 export function Encrypt(word, key) {
  if (key) key = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(word, key || defaultKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
    iv: '',
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

/**
 * 解密
 * @param {String} word
 * @param {String} key
 * @returns {String}
 */
export function Decrypt(word, key) {
  if (key) key = CryptoJS.enc.Utf8.parse(key);
  const decrypt = CryptoJS.AES.decrypt(word, key || defaultKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
    iv: '',
  });
  return CryptoJS.enc.Utf8.stringify(decrypt);
}
