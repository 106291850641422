import React, { Component } from 'react'
import { getTeamData } from '@/api'
import './index.scss'

import Reward from './components/Reward'
import ShareInfo from './components/ShareInfo'

export default class Share extends Component {

  state = {
    // 团队数据
    temData: {
      income: 0,
      num: 0,
      other: 0,
      win: 0,
    },
  }

  /**
   * 组件挂载钩子
   */
   componentDidMount() {
    // 获取团队数据
    this.getTeamData()
  }

  /**
   * 组件将卸载钩子
   */
  componentWillUnmount() {
    this.setState = () => {}
  }

  /**
   * 获取团队数据
   */
  getTeamData = async () => {
    const { data } = await getTeamData()
    this.setState({ temData: data })
  }

  render() {
    const { temData } = this.state
    const { indexData } = this.props
    return (
      <div className='share_page'>
        <img
          className='banner'
          src={require('./assets/img_banner.png')}
          alt='banner'
        />
        <div className="main_wrap">
          <div className='reward_wrap'>
            <Reward temData={temData} />
          </div>
          <ShareInfo indexData={indexData} temData={temData} />
        </div>
      </div>
    )
  }
}
