import React, { Component } from 'react'
import {formatNumberWithCommas} from "../../../../utils/util";
import './index.scss'

export default class Reward extends Component {
  render() {
    const { temData } = this.props
    return (
      <div className='reward'>
        <div className='main_value'>
          <div className='name'>Referral reward (USDT)</div>
          <div className='value'>{formatNumberWithCommas(temData.income.toFixed(2),2)}</div>
        </div>
        <div className='sub_value'>
          <div className="item">
            <div className='name'>
              <img
                className='icon'
                src={require('./assets/icon_user.svg').default}
                alt='user'
              />
              <span>Direct</span>
            </div>
            <div className='value'>{temData.num}</div>
          </div>
          <div className="item">
            <div className='name'>
              <img
                className='icon'
                src={require('./assets/icon_users.svg').default}
                alt='user'
              />
              <span>Indirect</span>
            </div>
            <div className='value'>{temData.other}</div>
          </div>
        </div>
      </div>
    )
  }
}
