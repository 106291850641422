import React, { Component } from 'react'
import './index.scss'
import {formatNumberWithCommas} from "../../../../utils/util";

export default class CardInfo extends Component {
  render() {
    const { amountInfo, onShowOrderPopup } = this.props
    return (
      <div className='card_info'>
        <div className='item'>
          <div className='name'>Expected earnings for today</div>
          <div className='value'>
            <span className='symbol'>$</span>
            <span> {formatNumberWithCommas(amountInfo.income24.toFixed(2),2)}</span>
          </div>
        </div>
        <div className='item'>
          <div className='name'>Cumulative income</div>
          <div className='value'>
            <span className='symbol'>$</span>
            <span> {formatNumberWithCommas(amountInfo.income.toFixed(2),2)}</span>
          </div>
        </div>
        <div
          className='item'
          onClick={onShowOrderPopup}
        >
          <div className='name'>Order volume</div>
          <div className='value arrow_wrap'>
            <span> {amountInfo.num}</span>
            <img
              className='icon'
              src={require('./assets/icon_arrow_right.svg').default}
              alt='arrow'
            />
          </div>
        </div>
      </div>
    )
  }
}
