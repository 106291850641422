import React, { Component, createRef } from "react";
import { Popup, Checkbox, Button, Toast, NavBar } from "react-vant";
import LoansForm from "./components/LoansForm";
import { getLoansInfo, addLoans } from "@/api";
import { formatNumberWithCommas } from "@/utils/util.js";
import "./index.scss";
import RecordPopup from "./components/RecordPopup";
import Agreement from "./components/Agreement";

export default class Loans extends Component {
  inputRef = createRef();
  state = {
    info: {},
    list: [],
    agreement: false,
    recordPopupShow: false,
    agreementPopupShow: false,
  };

  componentDidMount() {
    this.initData();
  }

  changeRecordPopupShow(val) {
    this.setState({
      recordPopupShow: val,
    });
  }

  changeAgreementPopupShow(val) {
    this.setState({
      agreementPopupShow: val,
    });
  }

  initData = async () => {
    const {
      data: { info, list },
    } = await getLoansInfo();
    this.setState({ info, list });
  };

  onSubmit = async () => {
    const params = this.inputRef.current.getFromData();
    if (!params) return;
    const { rpMsg } = await addLoans(params);
    Toast.success(rpMsg);
    this.inputRef.current.resetData();
  };

  render() {
    const { info, list, agreement, recordPopupShow, agreementPopupShow } =
      this.state;
    const { visible, onClose } = this.props;
    return (
      <Popup
        className="online_help_popup_container"
        visible={visible}
        onClickOverlay={onClose}
        position="right"
      >
        <NavBar
          className="navbar"
          title="I want to borrow money"
          border={false}
          leftArrow
          rightText="Record"
          fixed
          placeholder
          onClickLeft={onClose}
          onClickRight={() => this.changeRecordPopupShow(true)}
        />
        <RecordPopup
          visible={recordPopupShow}
          onClose={() => this.changeRecordPopupShow(false)}
        />
        <Agreement
          visible={agreementPopupShow}
          onClose={() => this.changeAgreementPopupShow(false)}
        />
        <div className="content_wrap">
          <div className="flex-col items-center header-wrap">
            <div className="flex-col items-center circle-box">
              <div className="s1">In the monetary</div>
              <div className="s2">
                <span className="number u-m-r-5">
                  {formatNumberWithCommas((info.df || 0).toFixed(2), 2)}
                </span>
                /USDT
              </div>
            </div>

            <div
              className="btn"
              onClick={() => this.changeRecordPopupShow(true)}
            >
              To repay
            </div>

            <div className="infos">
              <div className="items">
                <div className="t1">The cumulative monetary(USDT)</div>
                <div className="t2">
                  ≈ {formatNumberWithCommas((info.lj || 0).toFixed(2), 2)}
                </div>
              </div>
              <div className="items">
                <div className="t1">Number of outstanding payments</div>
                <div className="t2 spec">{info.num || 0}</div>
              </div>
            </div>
          </div>
          <LoansForm ref={this.inputRef} lenders={list} />
        </div>
        <div className="fixed-btn">
          <div className="agreement">
            <Checkbox
              value={agreement}
              onChange={(val) =>
                this.setState({
                  agreement: val,
                })
              }
              checkedColor="#2979ff"
              shape="square"
            >
              I have read and agree
            </Checkbox>
            <span
              style={{ marginLeft: ".1rem" }}
              onClick={() => this.changeAgreementPopupShow(true)}
            >
              Apply for a currency agreement
            </span>
          </div>
          <Button
            disabled={!agreement}
            color="#2979ff"
            className="btn"
            onClick={this.onSubmit}
          >
            Click Apply for credit
          </Button>
        </div>
      </Popup>
    );
  }
}
