import React, { Component } from 'react'
import { Field, ActionSheet, Icon, Toast } from 'react-vant'
import './index.scss'

import MUploader from '../MUploader'

export default class LoansForm extends Component {
    state = {
        amount: '',
        dkImgs: {},
        showLender: false,
        currentLenderIndex: 0,
    }

    onSelectLender = (data, index) => {
        console.log('data', data)
        this.setState({
            currentLenderIndex: index,
            showLender: false,
        })
    }

    changeLenderActionShow = value => {
        this.setState({
            showLender: value,
        })
    }

    onUploadDkImg = (name, imgKey, res) => {
        this.setState({
            dkImgs: {
                ...this.state.dkImgs,
                [imgKey]: res,
            },
        })
    }

    getFromData = () => {
        const { amount, dkImgs } = this.state
        if (!amount || amount < 0 || Object.keys(dkImgs).length !== 6) {
            Toast.info('Please check input')
            return null
        }
        return {
            num: amount,
            pid: this.currentLenderData.id,
            ...dkImgs,
        }
    }

    resetData = () => {
        this.setState({
            amount: '',
            dkImgs: {},
            showLender: false,
            currentLenderIndex: 0,
        })
    }

    get currentLenderData() {
        return this.props.lenders[this.state.currentLenderIndex] || {}
    }

    get interest() {
        const { ratio, zq } = this.currentLenderData
        return ((Number(ratio || 0) / 100) * Number(zq || 0) * Number(this.state.amount || 0)).toFixed(4)
    }

    render() {
        const { amount, showLender } = this.state
        const { lenders } = this.props
        return (
            <div className='loans-form-wrap' style={{ marginTop: '-.8rem', padding: '.3rem' }}>
                <div className='card'>
                    <div className='desc'>
                        <span>Amount Borrowed (usdt)</span>
                        <span>Credit cycle (days)</span>
                    </div>
                    <div className='input-wrap'>
                        <Field
                            style={{ '--rv-cell-horizontal-padding': 0 }}
                            name='amount'
                            value={amount}
                            type='number'
                            center
                            placeholder='Please enter amount'
                            onChange={val => this.setState({ amount: val })}
                            button={
                                <div onClick={() => this.changeLenderActionShow(true)}>
                                    <span className='day'>{this.currentLenderData.zq || 0}</span>
                                    <Icon name='arrow-down' size='.30rem' className='icon' />
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* <!-- 贷币机构信息展示 --> */}
                <div className='card pd'>
                    <div className='desc'>
                        <span>Daily rate</span>
                        <span>{this.currentLenderData?.ratio || 0}%</span>
                    </div>
                    <div className='desc'>
                        <span>Reimbursement means</span>
                        <span>The principal and interest will be repaid once due</span>
                    </div>
                    <div className='desc'>
                        <span>interest</span>
                        <span>{this.interest || 0}</span>
                    </div>
                    <div className='desc'>
                        <span>lenders</span>
                        <span>{this.currentLenderData?.name}</span>
                    </div>
                </div>

                {/* <!-- 上传图片区域 --> */}
                <div className='title'>Credit Loan (photo upload)</div>
                <div className='card upload-wrap'>
                    <MUploader imgKey='p1' name='ID card/driver license' uploadSuccess={this.onUploadDkImg} />
                    <MUploader imgKey='p2' name='Payroll' uploadSuccess={this.onUploadDkImg} />
                    <MUploader imgKey='p3' name='Proof of real estate' uploadSuccess={this.onUploadDkImg} />
                    <MUploader imgKey='p4' name='Credit score' uploadSuccess={this.onUploadDkImg} />
                    <MUploader imgKey='p5' name='Proof of property' uploadSuccess={this.onUploadDkImg} />
                    <MUploader imgKey='p6' name='Guarantor Information' uploadSuccess={this.onUploadDkImg} />
                </div>

                <ActionSheet
                    cancelText='Cancel'
                    visible={showLender}
                    onSelect={this.onSelectLender}
                    onCancel={() => this.changeLenderActionShow(false)}
                    actions={lenders.map(i => ({ ...i, text: i.name, name: i.zq }))}
                />
            </div>
        )
    }
}
