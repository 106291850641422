import React, { Component } from 'react'
import './index.scss'
import Home from '@/pages/Home'
import Help from '@/pages/Help'
import Share from '@/pages/Share'
import Account from '@/pages/Account'
import Pledge from '@/pages/Pledge'

const tabBar = [
  {
    name: 'Home',
    img: 'icon_tab1.svg',
    imgActive: 'icon_tab1_active.svg',
    Component: Home,
  },
  {
    name: 'Help',
    img: 'icon_tab2.svg',
    imgActive: 'icon_tab2_active.svg',
    Component: Help,
  },
  {
    name: 'Pledge',
    img: 'icon_tab3.svg',
    imgActive: 'icon_tab3_active.svg',
    Component: Pledge,
  },
  {
    name: 'Share',
    img: 'icon_tab4.png',
    imgActive: 'icon_tab4_active.png',
    Component: Share,
  },
  {
    name: 'Account',
    img: 'icon_tab5.svg',
    imgActive: 'icon_tab5_active.png',
    Component: Account,
  },
]

export default class TabBar extends Component {

  state = {
    // tab 索引
    tabIndex: 0,
  }

  /**
   * 切换 tab
   * @param {Number} tabIndex
   */
  handleChangeTab = (tabIndex) => {
    return () => {
      this.setState({ tabIndex })
    }
  }

  render() {
    const { tabIndex } = this.state
    const { indexData } = this.props;
    const { Component } = tabBar[tabIndex]
    const virtualAccountAddress = window.localStorage.getItem('virtualAccountAddress')
    let theme = null
    if(virtualAccountAddress) {
      theme = {backgroundImage: 'linear-gradient(180deg, #F29E65 0%, #F4CC97 40%, #F4F7FA 100%)'}
    }
    return (
      <>
        <div className='tab_body' style={theme}>
          <Component indexData={indexData} />
        </div>
        <div className='tab_bar'>
          {
            tabBar.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`item ${tabIndex === index ? 'active' : ''}`}
                  onClick={this.handleChangeTab(index)}
                >
                  <img
                    className='icon'
                    src={require(`./assets/${tabIndex === index ? item.imgActive : item.img}`)}
                    alt={item.name}
                  />
                  <div className='name'>{item.name}</div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
}
