import React, {Component} from 'react'
import './index.scss'
import {Icon, Popup, Toast} from 'react-vant';
import {qhxn} from "../../../../api";
import {formatNumberWithCommas} from "../../../../utils/util";

export default class TotalData extends Component {
    state = {
        showDemoSwitchPopup: false,
        demoList: {
            index: 0,
            list: [10000, 100000, 1000000]
        }
    }
    openPopUp = () => {
        const {indexData} = this.props
        if(indexData.xnqh!==0) {
            // 2 直接切换号
            this.switchAccount()
            return
        }
        this.setState({showDemoSwitchPopup: true})
    }
    closePopUp = () => {
        this.setState({showDemoSwitchPopup: false})
    }

    setDemoChoice = (index) => {
        const {demoList} = this.state
        demoList.index = index
        this.setState({demoList: demoList})
    }

    switchAccount = async () => {
        const {indexData} = this.props
        const loading = Toast.loading()
        if (indexData.xnqh === 2) {
            // 2当前是虚拟号，可以切换到正常账户
            window.localStorage.removeItem('virtualAccountAddress')
            loading.clear()
            window.location.reload()
            return
        }
        if (indexData.xnqh === 0 || indexData.xnqh === 1) {
            // 切换到虚拟号
            const params = {
                type: this.state.demoList.index
            }
            const {data} = await qhxn(params)
            window.localStorage.setItem('virtualAccountAddress', data.uname)
            loading.clear()
            window.location.reload()
            return
        }
        loading.clear()
    }

    render() {
        const address = localStorage.getItem('address')
        const {indexData} = this.props
        const {showDemoSwitchPopup, demoList} = this.state
        const virtualAccountAddress = window.localStorage.getItem('virtualAccountAddress')
        let theme = null
        if(virtualAccountAddress) {
            theme = {backgroundImage: 'linear-gradient(180deg, #F29E65 0%, #F5E3B0 100%)'}
        }
        return (
            <div className='total_data'>
                <div className='address_info'>
                    <div className='name'>
                         <div className='title'>Welcome to {document.title}</div> 
                        {/* <div className='title'>Artificial Intelligence Contract</div> */}
                        <div className='sub_title'>Artificial intelligence trading</div>
                    </div>
                    <div className='value'>
                        <img
                            className='icon_link'
                            src={require('./assets/icon_link.svg').default}
                            alt='link'
                        />
                        <span>{address.substring(address.length - 8)}</span>
                    </div>
                </div>
                <div className='amount_info'>
                    <div className='amount_item'>
                        <div className='item_name'>Total earnings (USDT)</div>
                        <div className='item_value'>{formatNumberWithCommas(indexData.income.toFixed(2),2)}</div>
                    </div>
                    <div className='amount_item'>
                        <div className='item_name'>Earnings 24h (USDT)</div>
                        <div className='item_value'>{formatNumberWithCommas(indexData.income24.toFixed(2),2)}</div>
                    </div>
                </div>
                {
                    indexData.xnqh!==3&&
                    <div className='switch-demo'>
                        <div className='btn' style={theme} onClick={this.openPopUp}>{virtualAccountAddress?'LIVE':'DEMO'}</div>
                    </div>
                }
                {

                    <Popup visible={showDemoSwitchPopup}
                           position='bottom'>
                        <div className='demo-popup'>
                            <div className='popup-header'>
                                <img src={require('@/assets/images/usdt-icon.png')}/>
                                <span>Select virtual funds（USDT）</span>
                                <span className='close' onClick={this.closePopUp}><Icon name="cross" size="14"/></span>
                            </div>
                            <div className='content'>
                                <div className='list'>
                                    {
                                        demoList.list.map((item, index) => {
                                            return (
                                                <div className={demoList.index === index ? 'active item' : 'item'}
                                                     key={index}
                                                     onClick={() => this.setDemoChoice(index)}>
                                                    {formatNumberWithCommas(item)}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='btn' onClick={this.switchAccount}>
                                CONFIRM
                            </div>
                        </div>

                    </Popup>
                }
            </div>
        )
    }
}
