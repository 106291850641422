import React, { Component } from 'react'
import Web3Eth from 'web3-eth'
import { Popup, Toast } from 'react-vant'
import PubSub from 'pubsub-js'
import { tokenAddress } from '@/config'
import ABI from '@/config/ABI.json'
import { buyProduct, reserveProduct } from '@/api'
import './index.scss'

const eth = new Web3Eth(Web3Eth.givenProvider)
const contract = new eth.Contract(ABI, tokenAddress)

export default class BuyPopup extends Component {

  state = {
    // 用户钱包地址
    address: localStorage.getItem('address'),
    // 用户 usdt 余额
    usdtBalance: 0,
    // 购买金额
    buyAmount: '',
  }

  /**
   * 组件挂载钩子
   */
  componentDidMount() {
    // 获取 usdt 余额
    this.getUsdtBalance()
  }

  /**
   * 获取 usdt 余额
   */
  getUsdtBalance = async () => {
    const { address } = this.state
    const balance = await contract.methods.balanceOf(address).call()
    this.setState({ usdtBalance: balance / 1000000 })
  }

  /**
   * 购买金额改变
   */
  handleBuyAmountChange = (event) => {
    this.setState({buyAmount: event.target.value})
  }

  /**
   * 购买金额失去焦点
   */
  handleBuyAmountBlur = (event) => {
    let { value } = event.target
    value = parseFloat(value)
    value = isNaN(value) ? '' : parseFloat(value.toFixed(2))
    event.target.value = value
    this.setState({buyAmount: value})
  }

  /**
   * 最大金额
   */
  handleMax = async () => {
    // await this.getUsdtBalance()
    // const { usdtBalance } = this.state
    // const { product } = this.props
    // this.setState({buyAmount: usdtBalance >= product.mnum ? product.mnum : usdtBalance })
    const { product } = this.props
    this.setState({buyAmount: product.mnum })
  }

  /**
   * 确认提交
   */
  handleConfirm = async () => {
    await this.getUsdtBalance()
    const { buyAmount, usdtBalance } = this.state
    const { indexData, product, getProductList } = this.props
    if (!buyAmount) return Toast.info('Please enter USDT amount')
    if (buyAmount < product.lnum) return Toast.info(`Minimum buy ${product.lnum}`)
    if (buyAmount > product.mnum) return Toast.info(`Maximum buy ${product.mnum}`)
    if (indexData.utype === 0 && indexData.state === 0 && usdtBalance < buyAmount)
      return Toast.info('Not enough USDT')
    const params = { id: product.id, num: buyAmount }
    product.yy ? await reserveProduct(params) : await buyProduct(params)
    Toast.success('Success')
    getProductList()
    PubSub.publish('getIndexData')
    this.handleClose()
  }

  /**
   * 关闭弹窗
   */
  handleClose = () => {
    this.setState({buyAmount: '' })
    this.props.onClose()
  }

  render() {
    const { buyAmount } = this.state
    const { visible, product } = this.props
    return (
      <Popup
        className='buy_popup_container'
        visible={visible}
        onClickOverlay={this.handleClose}
        position='right'
      >
        <div className='popup_header'>
          <img
            className='left_back'
            src={require('./assets/icon_nav_left.svg').default}
            alt='back'
            onClick={this.handleClose}
          />
        </div>
        <div className='popup_content'>
          <div className='detail_banner'>
            <img
              className='img_banner'
              src={require('./assets/img_pro.png')}
              alt='banner'
            />
            <div className='title'>Order Type</div>
            <div className='sub_title'>{product.zdgj === 1 ? 'Intelligent protocol to profit usdt' : 'Move bricks to profit USDT'}</div>
          </div>
          <div className='detail_info'>
            <div className='item_header'>
              <div className='item_info'>
                <img
                  className='item_icon'
                  src={product.logo}
                  alt='usdt'
                />
                <span>{product.name}</span>
              </div>
              <div className='action'>
                <div className='name'>Rate of return</div>
                <div className='value'>{product.profit}%</div>
              </div>
            </div>
            <div className='item_detail'>
              <div className='detail_item'>
                <div className='name'>limit(USDT)</div>
                <div className='value'>{product.lnum}-{product.mnum}</div>
              </div>
              <div className='detail_item'>
                <div className='name'>Income</div>
                <div className='value'>Every day</div>
              </div>
              <div className='detail_item'>
                <div className='name'>Cycle</div>
                <div className='value'>{product.days}Day</div>
              </div>
            </div>
            <div className='item_logos'>
              {
                product.logoList.map((logo) => {
                  return (
                    <img
                      key={logo}
                      className='logo_icon'
                      src={require(`@/assets/images/product_logo/${logo}.png`)}
                      alt='coin'
                    />
                  )
                })
              }
            </div>
          </div>
          <div className='detail_deal'>
            <div className='deal_title'>Escrow amount</div>
            <div className='input_content'>
              <input
                className='amount_input'
                type='number'
                placeholder='0.00'
                value={buyAmount}
                onChange={this.handleBuyAmountChange}
                onBlur={this.handleBuyAmountBlur}
              />
              <div className='max' onClick={this.handleMax}>MAX</div>
            </div>
            <div className='cash_tips'>Support hundreds of transactions to run trading strategies at the same time, each variety has an independent thread, automatically monitor quotation depth, strategy calculation, real-time monitoring of transaction conditions, and ensure the immediacy of transaction execution.</div>
          </div>
          <div className='detail_submit'>
            <div className='submit_btn' onClick={this.handleConfirm}>{product.yy ? 'Booking' : 'Confirm'}</div>
          </div>
        </div>
      </Popup>
    )
  }
}
