import React, { Component } from 'react'
import './index.scss'
import {formatNumberWithCommas} from '../../../../utils/util'
export default class MarketList extends Component {

  state = {
    // 展开的索引
    expanedIndex: 0,
  }

  /**
   * 切换展开
   * @param {Number} index
   */
  handleChangeExpan= (index) => {
    const { expanedIndex } = this.state
    return () => {
      this.setState({ expanedIndex: expanedIndex === index ? null : index })
    }
  }

  render() {
    const { expanedIndex } = this.state
    const { nodeData: { list = []} } = this.props
    return (
      <div className='market_list'>
        {
          list.map((item, index) => {
            return (
              <div key={item.id} className='market_item'>
                <div className='item_main' onClick={this.handleChangeExpan(index)}>
                  <div className='name'>
                    <img
                      className='icon'
                      src={require(`./assets/icon_market_${item.name}.png`)}
                      alt='coin'
                    />
                    <span>{item.name}</span>
                  </div>
                  <div className='value'>
                    <span>{formatNumberWithCommas(item.eth.toFixed(2),2)}</span>
                    <img
                      className='icon_arrow'
                      src={
                        expanedIndex === index ?
                        require('./assets/icon_arrow_up.svg').default :
                        require('./assets/icon_arrow_down.svg').default
                      }
                      alt='arrow'
                    />
                  </div>
                </div>
                <div
                  className='item_detail'
                  style={{
                    display: expanedIndex === index ? null : 'none',
                  }}
                >
                  <div className='header'>
                    <div className='label'>Currency</div>
                    <div className='label'>24h Volume</div>
                    <div className='label'>Liquidity</div>
                  </div>
                  <div className='list'>
                    <div className='coin_item'>
                      <div className='info'>
                        <img
                          className='icon'
                          src={require('./assets/icon_BitCoin.png')}
                          alt='coin'
                        />
                        <span>BitCoin</span>
                      </div>
                      <div className='value'>${formatNumberWithCommas(item.bv.toFixed(2),2)}</div>
                      <div className='num'>{item.bl}</div>
                    </div>
                    <div className='coin_item'>
                      <div className='info'>
                        <img
                          className='icon'
                          src={require('./assets/icon_Ethereum.png')}
                          alt='coin'
                        />
                        <span>Ethereum</span>
                      </div>
                      <div className='value'>${formatNumberWithCommas(item.ev.toFixed(2),2)}</div>
                      <div className='num'>{item.el}</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
