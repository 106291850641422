import React, { Component } from 'react'
import { Popup } from 'react-vant'
import './index.scss'

export default class OnlineHelpPopup extends Component {
  render() {
    const { visible, onClose, indexData } = this.props
    return (
      <Popup
        className='online_help_popup_container'
        visible={visible}
        onClickOverlay={onClose}
        position='right'
      >
        <div className='popup_header'>
          <img
            className='left_back'
            src={require('./assets/icon_nav_back.svg').default}
            alt='back'
            onClick={onClose}
          />
          <span>OnlineHelp</span>
        </div>
        <div className='iframe_wrap'>
          <iframe title='OnlineHelp' src={indexData.kf} />
        </div>
      </Popup>
    )
  }
}
