import React, { Component } from "react";
import { Popup, Toast, PasswordInput, NumberKeyboard } from "react-vant";
import { setmm } from "@/api";
import "./index.scss";

export default class addPsswordPopup extends Component {
  /**
   * 设置密码或提现
   */
  handleReceive = async () => {
    const { password } = this.state;
    const { indexData, onWithdraw } = this.props;
    if (indexData.zjd === 0) {
      if (
        password.onePassword.length < 6 ||
        password.confirmPassword.length < 6
      ) {
        return Toast.info("Please enter password");
      }
      if (password.onePassword !== password.confirmPassword) {
        return Toast.info("The two passwords do not match");
      }
      await setmm({ code: password.confirmPassword });
      // onWithdraw(password.confirmPassword);
      this.close();
    } else {
      if (password.withdrawPasswod.length < 6) {
        return Toast.info("Please enter password");
      }
      onWithdraw(password.withdrawPasswod);
      this.close();
    }
  };
  state = {
    password: {
      onePassword: "",
      confirmPassword: "",
      withdrawPasswod: "",
    },
    FocusSetting: false,
    FocusConfirm: false,
    FocusWithdraw: false,
    closeOnClickOverlay: false,
  };

  close = () => {
    const { password } = this.state;
    const { onClose } = this.props;
    password.onePassword = "";
    password.confirmPassword = "";
    password.withdrawPasswod = "";
    this.setState({ password: password });
    onClose();
  };
  //设置第一次密码
  setOnePassword = (e) => {
    const { password } = this.state;
    password.onePassword = e;
    this.setState({ password: password });
  };
  setConfirmPassword = (e) => {
    const { password } = this.state;
    password.confirmPassword = e;
    this.setState({ password: password });
  };
  setWithdrawPasswod = (e) => {
    const { password } = this.state;
    password.withdrawPasswod = e;
    this.setState({ password: password });
  };
  //第一次输入获取焦点
  onFocusSetting = (e) => {
    // const { FocusSetting } = this.state;
    // FocusSetting = true;
    this.setState({ FocusSetting: true });
    // e.target.blur();
    console.log(e.target);
    e.target.readOnly = true;
    // const a = document.getElementsByClassName("my-password-input");
    // console.log(a);
    e.preventDefault(); // 阻止默认行为，禁止弹出输入
  };
  // 确认获取焦点
  onFocusConfirm = (e) => {
    // const { FocusConfirm } = this.state;
    // FocusConfirm = true;
    this.setState({ FocusConfirm: true });
    e.target.readOnly = true;
    // e.target.blur();
  };
  // 提现获取焦点
  onFocusWithdraw = (e) => {
    // const { FocusConfirm } = this.state;
    // FocusConfirm = true;
    this.setState({ FocusWithdraw: true });
    e.target.readOnly = true;
    // e.target.blur();
  };
  //第一次输入密码
  setValue = (e) => {
    const { password } = this.state;
    password.onePassword = e;
    this.setState({ password: password });
  };
  //确认密码
  setConfirmValue = (e) => {
    const { password } = this.state;
    password.confirmPassword = e;
    this.setState({ password: password });
  };
  //提现密码
  setWithdrawValue = (e) => {
    const { password } = this.state;
    password.withdrawPasswod = e;
    this.setState({ password: password });
  };

  render() {
    const {
      password,
      closeOnClickOverlay,
      FocusSetting,
      FocusConfirm,
      FocusWithdraw,
    } = this.state;
    // const FocusSetting = true;
    const { visible, indexData } = this.props;
    return (
      <>
        <Popup
          className={`join_popup_container join_popup_box ${
            indexData.zjd === 0 ? "no-close-button" : ""
          }`}
          visible={visible}
          onClickOverlay={indexData.zjd === 0 ? () => {} : this.close}
        >
          <div className="join_popup_content">
            <div className="join_popup">
              <div className="header">
                <div>
                  {indexData.zjd === 0
                    ? "Please Setting Password"
                    : "Please Enter Password"}
                </div>
                <img
                  className="icon_close"
                  src={require("./assets/icon_close.png")}
                  alt="close"
                  onClick={this.close}
                />
              </div>

              <div className="content_wrap">
                {indexData.zjd === 0 ? (
                  <div className="row-password">
                    <div>Setting password</div>
                    <div onClick={this.onFocusSetting}>
                      <PasswordInput
                        type="number"
                        value={password.onePassword}
                        mask={false}
                        length={6}
                        className="password-box my-password-input"
                        onChange={this.setOnePassword}
                        // onFocus={this.onFocusSetting}
                      />
                    </div>
                  </div>
                ) : null}
                {indexData.zjd === 0 ? (
                  <div className="row-password">
                    <div>Confirm password</div>
                    <div onClick={this.onFocusConfirm}>
                      <PasswordInput
                        type="number"
                        value={password.confirmPassword}
                        mask={true}
                        length={6}
                        className="password-box"
                        // onFocus={this.onFocusConfirm}
                        onChange={this.setConfirmPassword}
                      />
                    </div>
                  </div>
                ) : null}
                {indexData.zjd === 1 ? (
                  <div onClick={this.onFocusWithdraw}>
                    <PasswordInput
                      type="number"
                      value={password.withdrawPasswod}
                      mask={true}
                      length={6}
                      className="password-box new-password"
                      onChange={this.setWithdrawPasswod}
                    />
                  </div>
                ) : null}
                <div className="join_btn" onClick={this.handleReceive}>
                  {indexData.zjd === 0 ? "Confirm" : "WITHDRAW"}
                </div>
              </div>
            </div>
          </div>
          <div>
            <NumberKeyboard
              transition={false}
              // zIndex={2009}
              maxlength={6}
              value={password.withdrawPasswod}
              visible={FocusWithdraw}
              onChange={this.setWithdrawValue}
              onBlur={() => {
                this.setState({ FocusWithdraw: false });
              }}
            />
          </div>
        </Popup>
        <NumberKeyboard
          transition={false}
          zIndex={2001}
          maxlength={6}
          value={password.onePassword}
          visible={FocusSetting}
          onChange={this.setValue}
          onBlur={() => {
            this.setState({ FocusSetting: false });
          }}
        />
        <NumberKeyboard
          transition={false}
          zIndex={2001}
          maxlength={6}
          value={password.confirmPassword}
          visible={FocusConfirm}
          onChange={this.setConfirmValue}
          onBlur={() => {
            this.setState({ FocusConfirm: false });
          }}
        />
      </>
    );
  }
}
