import React, { Component } from 'react'
import { Popup } from 'react-vant'
import './index.scss'

export default class IntroducePopup extends Component {
  render() {
    const { visible, onClose } = this.props;
    return (
      <Popup
        className='introduce_popup_container'
        visible={visible}
        onClickOverlay={onClose}
        position='right'
      >
        <img
          className='left_back'
          src={require('./assets/icon_nav_left.svg').default}
          alt='back'
          onClick={onClose}
        />
        <div className='content_wrap'>
          <div className='intro_title'>AI Trading</div>
          <div className='intro_subtitle'>The bottom layer of enterprise-level AI, leading algorithms for quantitative trading, automatic machine learning, and hyperparameter optimization to rapidly improve the efficiency of AI quantitative processing</div>
          <div className='intro_card_info'>
            <div className='intro_card_info_content'>
              <div className='item'>
                <img
                  className='icon'
                  src={require('./assets/icon_safe.svg').default}
                  alt='safe'
                />
                <div>100% Security</div>
              </div>
              <div className='item'>
                <img
                  className='icon'
                  src={require('./assets/icon_safe.svg').default}
                  alt='safe'
                />
                <div>No holidays</div>
              </div>
              <div className='item'>
                <img
                  className='icon'
                  src={require('./assets/icon_safe.svg').default}
                  alt='safe'
                />
                <div>Immediately</div>
              </div>
            </div>
          </div>
          <div className='intro_detail'>
            <div className='item'>
              <div className='name'>Earnings calculation</div>
              <div className='content'>Apply for entrustment to use artificial intelligence trading, and the current settlement income will be automatically settled according to the entrusted strategic product</div>
            </div>
            <div className='item'>
              <div className='name'>About liquidated damages</div>
              <div className='content'>After the entrusted order is completed, the system will automatically return the principal to the user's account, and the user can apply for withdrawal.</div>
            </div>
          </div>
        </div>
        <div className='popop_footer' onClick={onClose}>
          <div className='submit_btn'>Join hosting</div>
        </div>
      </Popup>
    )
  }
}
