import axios from 'axios'
import qs from 'qs'
import { baseURL } from '@/config'
import { Toast } from 'react-vant'

const service = axios.create({
  baseURL,
  timeout: 60000,
  auth: true,
  loading: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

service.interceptors.request.use(
  (config) => {
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
     if (config.type !== 'file') { config.data = qs.stringify(config.data); }
    //   config.data = qs.stringify(config.data)
    }
    if (config.auth) config.headers.token = localStorage.getItem('token')
    if (config.loading) {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
      })
    }
    return config;
  },
  (error) => Promise.reject(error),
)

service.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.rpCode !== '000000') {
      Toast.info(data.rpMsg || 'Error')
      return Promise.reject(new Error(data.rpMsg || 'Error'))
    }
    return data
  },
  (error) => {
    Toast.info(error.message || 'Error')
    return Promise.reject(error)
  },
)

export default service
