import React, { Component } from 'react'
import moment from 'moment-timezone'
import { Popup } from 'react-vant'
import { getWithdrawalRecord } from '@/api'
import './index.scss'
import {formatNumberWithCommas} from "../../../../utils/util";

export default class RecordPopup extends Component {

  state = {
    // 记录列表
    recordList: [],
  }

  /**
   * 获取提现记录
   */
  getWithdrawalRecord = async () => {
    const { data } = await getWithdrawalRecord()
    this.setState({recordList: data})
  }

  /**
   * 弹窗打开触发
   */
   onOpen = () => {
    // 获取提现记录
    this.getWithdrawalRecord()
  }

  /**
   * 日期格式化
   */
   dateFormat = (value) => {
    const tz = moment.tz.guess()
    return moment(value * 1000)
      .tz(tz)
      .format("MMMM D, YYYY h:mm:ss A")
  }

  /**
   * 状态格式化
   */
  statusFormat = (value) => {
    switch (value) {
      case 0:
        return 'Applying'
      case 1:
        return 'Success'
      case 2:
        return 'Failed'
      default:
        return ''
    }
  }

  render() {
    const { recordList } = this.state
    const { visible, onClose } = this.props
    return (
      <Popup
        className='record_popup_container'
        visible={visible}
        onClickOverlay={onClose}
        position='right'
        onOpen={this.onOpen}
      >
        <div className='popup_header'>
          <img
            className='icon_left'
            src={require('./assets/icon_nav_left.svg').default}
            alt='back'
            onClick={onClose}
          />
          <span>Record</span>
        </div>
        <div className='popup_content'>
          <div className='record_content'>
            {
              recordList.length ?
                recordList.map((record) => {
                  return (
                    <div className='record_item'>
                      <div className='type_content'>
                        <img
                          className='icon_usdt'
                          src={require('./assets/icon_record_usdt.svg').default}
                          alt='usdt'
                        />
                        <div className='type_info'>
                          <div className='name'>{this.statusFormat(record.status)}</div>
                          <div className='date'>{this.dateFormat(record.ctime)}</div>
                        </div>
                      </div>
                      <div className='amount_content'>
                        <div className='coin_amount'>{formatNumberWithCommas(record.num,2)}USDT</div>
                      </div>
                    </div>
                  )
                }) :
                <div className='no_data'>
                  <img
                    src={require('./assets/icon_nodata.png')}
                    alt='no data'
                  />
                  <div className='no_info'>No record</div>
                </div>
            }
          </div>
        </div>
      </Popup>
    )
  }
}
