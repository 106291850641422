import React, {Component} from 'react'
import PubSub from 'pubsub-js'
import {Toast} from 'react-vant'
import {withdrawal} from '@/api'
import './index.scss'
import bankIcon from './assets/bank.png'
import usdtIcon from './assets/USDT.png'
import WithdrawPopup from "../WithdrawPopup";

export default class Withdraw extends Component {

    state = {
        // 提现金额
        tabList: {
            index: 0,
            list: [
                {
                    icon: usdtIcon,
                    name: 'Cryptocurrency deposits withdrawal',
                },
                {
                    icon: bankIcon,
                    name: 'Bankcard withdrawal',
                    index: 1
                },
            ]
        },
        showWithdrawPopup: false
    }

    setTabIndex = (index) => {
        const {tabList} = this.state
        tabList.index = index
        this.setState({tabList})
    }

    closeWithdrawPopup = () => {
        this.setState({showWithdrawPopup: false})
    }

    openWithdrawPopup = () => {
        this.setState({showWithdrawPopup: true})
    }

    render() {
        const {indexData} = this.props
        const {tabList, showWithdrawPopup} = this.state
        return (
            <>
                <div className='withdraw_way'>
                    <div className='title'>Select a Deposit and withdrawal method</div>
                    <div className='ways'>
                        {tabList.list.map((item, index) => {
                            return (
                                <div className={`item ${tabList.index === index ? 'active' : ''}`}
                                     key={index}
                                     onClick={() => this.setTabIndex(index)}>
                                    <img className='icon' src={item.icon}/>
                                    <span>{item.name}</span>
                                    {tabList.index === index &&
                                        <img src={require('./assets/ratio.png')} className='ratio'/>}
                                </div>
                            )
                        })}
                    </div>
                    <div className='desc'>
                        The USDT withdrawn will arrive at your wallet address within 24 hours after applying for
                        withdrawal.
                        The minimum single withdrawalis 30USDT, and the withdrawal fee is 3 USDT of the ! withdrawal
                        amount.
                    </div>
                    <div className='next-btn' onClick={this.openWithdrawPopup}>NEXT</div>
                </div>
                {showWithdrawPopup &&
                    <WithdrawPopup indexData={indexData}
                                   visible={showWithdrawPopup}
                                   way={tabList.index}
                                   onClose={this.closeWithdrawPopup}/>
                }
            </>
        )
    }
}
