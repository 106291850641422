import React, { Component } from 'react'
import './inde.scss'
import {formatNumberWithCommas} from "../../../../utils/util";

export default class ProductItem extends Component {
  render() {
    const { product, onBuy } = this.props
    return (
      <div className='product_item'>
        <div className='header'>
          <div className='info'>
            <img
              className='icon'
              src={product.logo}
              alt='product'
            />
            <span>{product.name}</span>
          </div>
          {
            product.xnum >= 0 &&
            <div className='limited'>Limited to <span>{product.xnum}</span> purchase</div>
          }
        </div>
        <div className='detail'>
          <div className='detail_item'>
            <div className='name'>limit(USDT)</div>
            <div className='value'>{formatNumberWithCommas(product.lnum)}-{formatNumberWithCommas(product.mnum)}</div>
          </div>
          <div className='detail_item'>
            <div className='name'>Rate of return</div>
            <div className='value'>{product.profit}%</div>
          </div>
          <div className='detail_item'>
            <div className='name'>Cycle</div>
            <div className='value'>{product.days}Day</div>
          </div>
        </div>
        <div className='coins'>
          {
            product.logoList.map((logo) => {
              return (
                <img
                  key={logo}
                  className='icon'
                  src={require(`@/assets/images/product_logo/${logo}.png`)}
                  alt='coin'
                />
              )
            })
          }
        </div>
        <div
          className={`submit_btn ${product.disabled ? 'is-disabled' : ''}`}
          onClick={onBuy(product)}
        >{product.yy ? 'Booking' : 'To Buy'}</div>
      </div>
    )
  }
}
