import React, { Component } from 'react'
import './index.scss'
import JoinPopup from '../JoinPopup'
import {formatNumberWithCommas} from "../../../../utils/util";

export default class JoinInfo extends Component {

  state = {
    // 加入弹窗
    showJonPopup: false,
  }

  /**
   * 显示弹窗
   */
  handleShowPopup = () => {
    this.setState({ showJonPopup: true })
  }

  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    this.setState({ showJonPopup: false })
  }

  render() {
    const { showJonPopup } =  this.state
    const { indexData, nodeData } = this.props
    return (
      <>
        <div className='join_info'>
          <div className='info_wrap'>
            <div className='info'>
              <div className="title">{indexData.state ? 'Already joined' : 'Open transaction!'}</div>
              <div className='sub_title'>2000+ base factor library with AI support to more catch derivative factors, one step ahead!</div>
            </div>
            {
              !indexData.state &&
              <div className="join_btn" onClick={this.handleShowPopup}>
                <img
                  className='icon_add'
                  src={require('./assets/icon_add.svg').default}
                  alt='add'
                />
              </div>
            }
          </div>
          <div className='data_wrap'>
            <data className='data_item'>
              <div className='name'>Number of people</div>
              <div className='value'>{nodeData.participant}</div>
            </data>
            <data className='data_item'>
              <div className='name'>Total revenue</div>
              <div className='value'>{formatNumberWithCommas(nodeData.totalOutput,2)} USDT</div>
            </data>
          </div>
        </div>
        <JoinPopup
          visible={showJonPopup}
          indexData={indexData}
          onClose={this.handleClosePopup}
        />
      </>
    )
  }
}
