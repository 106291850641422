import React, { Component } from 'react'
import './index.scss'

export default class Partner extends Component {
  render() {
    return (
      <div className='partner'>
        {
          Array.from(Array(9), (_, index) => {
            return (
              <div key={index} className='item'>
                <img src={require(`./assets/img_partner${index + 1}.png`)} alt='partner' />
              </div>
            )
          })
        }
      </div>
    )
  }
}
