import React, { Component } from "react";
import "./index.scss";
import Balance from "./components/Balance";
import Withdraw from "./components/USDTWithdraw";
import SelectWay from "./components/SelectWay";
import AddPasswordPopup from "./components/addPassowrdPopup";
import PubSub from "pubsub-js";
export default class Account extends Component {
  state = {
    // 加入弹窗
    showPasswordPopup: false,
  };
  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    const { indexData } = this.props;
    this.setState({ showPasswordPopup: false });
    setTimeout(() => {
      PubSub.publish("updateIndexData", {
        ...indexData,
        zjd: 1,
      });
    }, 2000);
  };

  async componentDidMount() {
    const { indexData } = this.props;
    if (indexData.zjd === 0) {
      this.setState({ showPasswordPopup: true });
    }
    // console.log("进来了");
  }
  render() {
    const { showPasswordPopup } = this.state;
    const { indexData } = this.props;
    return (
      <div className="account_page">
        <Balance indexData={indexData} />
        {/*<Withdraw indexData={indexData} />*/}
        <SelectWay indexData={indexData} />
        <AddPasswordPopup
          visible={showPasswordPopup}
          indexData={indexData}
          onClose={this.handleClosePopup}
        />
      </div>
    );
  }
}
