import React, { Component } from "react";
import "./index.scss";
import { Popup } from "react-vant";
import { formatNumberWithCommas } from "../../../../utils/util";

export default class Balance extends Component {
  state = {
    show: false,
  };

  /**
   * 最大金额
   */
  handleMax = async () => {
    const { onFromChange, currencyList, fromIndex } = this.props;
    onFromChange(currencyList.list[fromIndex].money);
  };

  /** 转化值的变化*/
  setNum = (e) => {
    const { onFromChange } = this.props;
    const address = e.target.value;
    onFromChange(address);
  };
  /**选择兑换的币 */
  selectToCoin = async (index) => {
    const { onSelectToCoin } = this.props;
    await onSelectToCoin(index);
    this.setState({ show: false });
  };
  render() {
    const { show } = this.state;
    const { fromNum, toNum, toIndex, fromIndex, currencyList } = this.props;
    return (
      <>
        <div className="convertBox">
          <div className="fromBox">
            <div className="leftFrom">
              <div>From</div>
              <div className="fromName">
                {" "}
                <img
                  className="usdtImg"
                  src={currencyList.list[fromIndex].img}
                />
                {currencyList.list[fromIndex].name}
              </div>
            </div>
            <div className="rightFrom">
              <div className="input_right">
                <input
                  className="input"
                  type="number"
                  placeholder="0.00"
                  value={fromNum}
                  onChange={this.setNum}
                  onBlur={this.setNum}
                />
                <div className="max" onClick={this.handleMax}>
                  MAX
                </div>
              </div>
              {/* 13 */}
            </div>
          </div>
          <div className="fromBox toBox">
            <div className="leftFrom">
              <div>To</div>
              {toIndex !== null ? (
                <div
                  className="fromName"
                  onClick={() => {
                    if (fromIndex === 0) {
                      this.setState({ show: true });
                    }
                  }}
                >
                  {" "}
                  <img
                    className="usdtImg"
                    src={currencyList.list[toIndex].img}
                  />
                  {currencyList.list[toIndex].name}
                </div>
              ) : (
                <div
                  className="chooseCoin"
                  onClick={() => this.setState({ show: true })}
                >
                  {" "}
                  Choose coin
                </div>
              )}
            </div>
            <div className="rightFrom">{toNum}</div>
          </div>
        </div>
        <Popup
          visible={show}
          position="bottom"
          style={{ height: "30%" }}
          onClickOverlay={() => this.setState({ show: false })}
        >
          <div className="coin">
            {currencyList.list.map((item, index) => {
              return index !== 0 ? (
                <div
                  key={index}
                  className="coinItem"
                  onClick={() => this.selectToCoin(index)}
                >
                  <img src={item.img} alt="" />
                  <div>
                    <div className="name"> {item.name}</div>
                    <div className="money">{item.money}</div>
                  </div>
                </div>
              ) : null;
            })}
          </div>
        </Popup>
      </>
    );
  }
}
