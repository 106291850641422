import React, { Component } from "react";
import MUploader from "../Upload/index.jsx";
import PubSub from "pubsub-js";
import { Toast } from "react-vant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  withdrawal,
  getPayAdds2,
  subRecharge,
  getAllBalance,
  confirmExchange,
} from "@/api";
import AddPasswordPopup from "../addPassowrdPopup";
import Convert from "../Convert";
import { formatNumberWithCommas } from "@/utils/util";
import "./index.scss";

export default class Withdraw extends Component {
  componentDidMount() {
    this.getAllBalance();
    this.getPayAdds2();
  }

  state = {
    //转化的值
    fromNum: "",
    //转化成的值
    toNum: 0,
    toIndex: null,
    //充值
    text: "Please do not send other types of assets to the above address. Doing so may result in the loss of your assets. After successful sending, the network node needs to confirm the receipt of the respective asset. Therefore, once you have completed the transfer, submit a screenshot of the successful transfer through the platform and wait for the system to review and confirm. After the system audit, the funds will be automatically added to your AI account for you. If you have any questions, please contact online customer service for verification.",
    // 体现
    text1:
      "Please any check the withdrawal address. Withdrawal of funds system will be based on the user's personal submission of the address for review, the audit passed automatically sent to your designated address account. The platform is not responsible for recovering the loss if the withdrawal address is entered incorrectly due to personal reasons. Only one withdrawal is supported per day. The minimum withdrawal amount is 30 Tether (USDT). A fee of 3 Tether (USDT) is charged for each withdrawal.",
    // 提现金额
    withdrawAmount: "",
    // 加入弹窗
    showPasswordPopup: false,
    chainList: {
      index: 0,
      list: ["ERC20"],
      // list: ['ERC20', 'TRC20', 'OMNI']
    },
    payList: {
      index: 0,
      list: ["RECEIVE", "SEND", "CONVERT"],
    },
    currencyList: {
      index: 0,
      list: [
        {
          name: "USDT",
          exhibit: "ERC20",
          img: require("./assets/usdt.png"),
          color: "#1BA27A",
          address: "",
          money: 0,
          p: 1,
        },
        {
          name: "USDC",
          exhibit: "ERC20",
          img: require("./assets/usdc.png"),
          color: "#2775CA",
          address: "",
          money: 0,
          p: 1,
        },
        {
          name: "BTC",
          exhibit: "Bitcoin",
          img: require("./assets/btc.png"),
          color: "#F7931A",
          address: "",
          money: 0,
          p: null,
        },
        {
          name: "ETH",
          exhibit: "ERC20",
          img: require("./assets/eth.png"),
          color: "#3E5BF2",
          address: "",
          money: 0,
          p: null,
        },
      ],
    },
    dkImgs: "",
    form: {
      // adds: window.localStorage.getItem('address'),
      adds: "",
      setNum: null,
      num: null,
      tbType: 0,
      lname: "ERC20",
    },
  };
  // 获取所有币余额
  getAllBalance = async () => {
    const { data } = await getAllBalance();
    const { currencyList } = this.state;
    const { indexData } = this.props;
    currencyList.list.forEach((item) => {
      item.money = Number(data[item.name.toLowerCase()]);
    });
    currencyList.list[2].p = data.btcRatio;
    currencyList.list[3].p = data.ethRatio;
    this.setState({ currencyList: this.state.currencyList });
    PubSub.publish("updateIndexData", {
      ...indexData,
      usdt: data.usdt,
    });
  };
  // 获取钱包地址
  getPayAdds2 = async () => {
    const { data } = await getPayAdds2();
    const { currencyList } = this.state;
    const coinAdd = {};
    data.forEach((item, index) => {
      coinAdd[item.name] = item.val;
    });
    currencyList.list.forEach((item1) => {
      item1.address = coinAdd[item1.name.toLowerCase()];
    });
    this.setState({ currencyList: this.state.currencyList });
  };

  /**
   * 显示弹窗
   */
  handleShowPopup = () => {
    this.setState({ showPasswordPopup: true });
  };

  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    this.setState({ showPasswordPopup: false });
  };

  /**
   * 提现金额改变
   */
  handleWithdrawAmountChange = (event) => {
    this.setState({ withdrawAmount: event.target.value });
  };

  /**
   * 提现金额失去焦点
   */
  handleWithdrawAmountBlur = (event) => {
    let { value } = event.target;
    value = parseFloat(value);
    value = isNaN(value) ? "" : parseFloat(value.toFixed(2));
    event.target.value = value;
    this.setState({ withdrawAmount: value });
  };

  /**
   * 最大金额
   */
  handleMax = async () => {
    const { currencyList } = this.state;
    // const { indexData } = this.props;
    this.setState({
      withdrawAmount: currencyList.list[currencyList.index].money,
    });
  };

  /**
   * 提现
   */
  handleWithdraw = async () => {
    const {
      withdrawAmount,
      form,
      payList,
      dkImgs,
      currencyList,
      fromNum,
      toIndex,
    } = this.state;
    const { indexData } = this.props;
    if (payList.index === 0) {
      //充值
      if (dkImgs === "") {
        return Toast.info("Please upload img");
      }
      if (!form.setNum || form.setNum <= 0) {
        return Toast.info("Please input the correct amount");
      }
      const formData = new FormData();
      formData.append("head_img", dkImgs);
      const bcode = currencyList.list[currencyList.index].name.toLowerCase();
      formData.append("num", form.setNum);
      formData.append("bcode", bcode);
      // form.append("bcode", this.rechargeData.topUp);
      await subRecharge(formData);
      Toast.success("Success");
      // this.setState({withdrawAmount: ''})
      // PubSub.publish('updateIndexData', {...indexData, usdt: data.usdt})
    } else if (payList.index === 1) {
      if (!withdrawAmount || withdrawAmount <= 0)
        return Toast.info("Please input the correct data");
      if (currencyList.index === 0 || currencyList.index === 1) {
        if (withdrawAmount < indexData.winmin)
          return Toast.info(`Minimum withdraw ${indexData.winmin}`);
      }

      return this.handleShowPopup();
    } else {
      if (!fromNum || fromNum <= 0)
        return Toast.info("Please input the correct data");
      await confirmExchange({
        inId: currencyList.list[toIndex].name.toLowerCase(),
        outId: currencyList.list[currencyList.index].name.toLowerCase(),
        num: fromNum,
      });
      await this.getAllBalance();
      this.setState({ fromNum: "", toNum: 0 });
      Toast.success("Success");
    }
  };
  //提现
  passwordWithdraw = async (code) => {
    const { withdrawAmount, form, currencyList } = this.state;
    const { indexData } = this.props;
    if (indexData.zjd === 0) {
      PubSub.publish("updateIndexData", { ...indexData, zjd: 1 });
    }
    form.num = withdrawAmount;
    await withdrawal({
      ...form,
      bcode: currencyList.list[currencyList.index].name,
      code,
    });
    const { data } = await getAllBalance();
    currencyList.list.forEach((item) => {
      item.money = Number(data[item.name.toLowerCase()]);
    });
    currencyList.list[2].p = data.btcRatio;
    currencyList.list[3].p = data.ethRatio;
    this.setState({ currencyList: this.state.currencyList });
    PubSub.publish("updateIndexData", {
      ...indexData,
      usdt: data.usdt,
    });

    Toast.success("Success");
    this.setState({ withdrawAmount: "" });
    PubSub.publish("updateIndexData", {
      ...indexData,
      usdt: data.usdt,
      zjd: 1,
    });
  };
  setPay = (index, pay) => {
    const { payList } = this.state;
    payList.index = index;
    // form.lname = pay
    this.setState({ payList: payList });
  };
  setCurrency = (index, pay) => {
    const { currencyList, toNum, fromNum } = this.state;
    currencyList.index = index;
    // form.lname = pay
    if (index === 0) {
      this.setState({
        currencyList: currencyList,
        withdrawAmount: "",
        fromNum: "",
        toNum: 0,
        toIndex: null,
      });
    } else {
      this.setState({
        currencyList: currencyList,
        withdrawAmount: "",
        fromNum: "",
        toNum: 0,
        toIndex: 0,
      });
    }

    // this.setState({ currencyList: currencyList, fromNum: 0, toNum: 0 });
  };
  onUploadDkImg = (name, imgKey, file) => {
    this.setState({
      dkImgs: file,
    });
  };
  setAddress = (e) => {
    const address = e.target.value;
    const { form } = this.state;
    form.adds = address;
    this.setState({ form: form });
  };
  setNum = (e) => {
    const address = e.target.value;
    const { form } = this.state;
    form.setNum = address; //提现数量
    this.setState({ form: form });
  };
  //兑换计算
  onFromChange = (num) => {
    const { toIndex, fromIndex, currencyList } = this.state;
    if (toIndex !== null) {
      let to = null;
      if (currencyList.index === 0) {
        to = Number(num) / currencyList.list[toIndex].p;
      } else {
        to = currencyList.list[currencyList.index].p * Number(num);
      }
      this.setState({ fromNum: num, toNum: to });
    } else {
      this.setState({ fromNum: num });
    }
  };
  onSelectToCoin = (index) => {
    this.setState({ toIndex: index, fromNum: "", toNum: 0 });
  };
  render() {
    const {
      withdrawAmount,
      chainList,
      payList,
      currencyList,
      text,
      showPasswordPopup,
      text1,
      fromNum,
      toNum,
      toIndex,
    } = this.state;
    const { indexData } = this.props;
    return (
      <div className="withdraw_wrap">
        <div className="chain-selection">
          <div className="option-list pay-list">
            {payList.list.map((item, index) => {
              return (
                <div
                  className={payList.index === index ? "item active" : "item"}
                  key={index}
                  onClick={(e) => this.setPay(index, item)}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className="title">
            {/* <img src={require('../SelectWay/assets/USDT.png')}/> */}
            <span>Currency Type</span>
          </div>
          <div className="option-list img-list">
            {currencyList.list.map((item, index) => {
              return (
                <img
                  className={currencyList.index === index ? "active" : ""}
                  alt=""
                  key={index}
                  onClick={(e) => this.setCurrency(index, item)}
                  src={item.img}
                  style={{ borderColor: item.color }}
                />
              );
            })}
          </div>
          <div className="dashed-line"></div>
          <div className="title titleMoneyBox">
            <img src={currencyList.list[currencyList.index].img} alt="" />
            <span>
              {currencyList.list[currencyList.index].name}{" "}
              {payList.index === 0
                ? "recharge"
                : payList.index === 1
                ? "withdrawal"
                : ""}
            </span>
            <span className="titleMoney">
              {currencyList.list[currencyList.index].money}{" "}
              {currencyList.list[currencyList.index].name}
            </span>
          </div>
          <div className="option-list A">
            {payList.index !== 2 ? (
              <div className="item active">
                {currencyList.list[currencyList.index].exhibit}
              </div>
            ) : null}
          </div>
        </div>
        {payList.index === 0 &&
        currencyList.list[currencyList.index].address ? (
          <div className="address-copy">
            {currencyList.list[currencyList.index].address.slice(0, 14) || ""}
            {currencyList.list[currencyList.index].address ? "..." : ""}
            {currencyList.list[currencyList.index].address.slice(-14) || ""}
          </div>
        ) : null}
        {payList.index === 0 &&
        currencyList.list[currencyList.index].address ? (
          <CopyToClipboard
            text={currencyList.list[currencyList.index].address}
            onCopy={() => Toast.info("Copy success")}
          >
            <div className="copy-address">Copy address</div>
          </CopyToClipboard>
        ) : null}
        {payList.index === 0 ? (
          <MUploader imgKey="p1" name=" " uploadSuccess={this.onUploadDkImg} />
        ) : null}
        {payList.index === 0 ? (
          <div className="title">Recharge amount</div>
        ) : null}
        {payList.index === 0 ? (
          <div className="input_wrap" style={{ marginBottom: "15px" }}>
            <input
              className="address input"
              type="number"
              value={this.state.form.setNum || ""}
              placeholder="Please input amount"
              onChange={this.setNum}
              onBlur={this.setNum}
            />
          </div>
        ) : null}
        {payList.index === 1 ? (
          <div className="title">Withdrawal address</div>
        ) : null}
        {payList.index === 1 ? (
          <div className="input_wrap" style={{ marginBottom: "15px" }}>
            <input
              className="address input"
              value={this.state.form.adds}
              placeholder="Please input address"
              onChange={this.setAddress}
              onBlur={this.setAddress}
            />
          </div>
        ) : null}
        {payList.index === 1 ? <div className="title">Quantity</div> : null}
        {payList.index === 1 ? (
          <div className="input_wrap">
            <input
              className="input"
              type="number"
              placeholder="0.00"
              value={withdrawAmount}
              onChange={this.handleWithdrawAmountChange}
              onBlur={this.handleWithdrawAmountBlur}
            />
            <div className="max" onClick={this.handleMax}>
              MAX
            </div>
          </div>
        ) : null}
        {/* {payList.index === 1 ? (
          <p className="cash_tips">
            Balance:$
            <span style={{ color: "#4A74F0" }}>
              {formatNumberWithCommas(indexData.usdt.toFixed(2), 2)}
            </span>
          </p>
        ) : null} */}
        {payList.index === 2 ? (
          <Convert
            currencyList={currencyList}
            fromNum={fromNum}
            toNum={toNum}
            fromIndex={currencyList.index}
            toIndex={toIndex}
            onSelectToCoin={this.onSelectToCoin}
            onFromChange={this.onFromChange}
          />
        ) : null}
        <p className="cash_tips">
          {payList.index === 0 ? text : payList.index === 1 ? text1 : ""}
        </p>
        {/* <p className='cash_tips'>{payList.index===0?text:indexData.winminTxt}</p> */}
        <div className="submit_btn" onClick={this.handleWithdraw}>
          {payList.index === 0
            ? "RECHARGE"
            : payList.index === 1
            ? "WITHDRAW"
            : "CONVERT"}
        </div>
        <AddPasswordPopup
          visible={showPasswordPopup}
          indexData={indexData}
          onClose={this.handleClosePopup}
          onWithdraw={this.passwordWithdraw}
        />
      </div>
    );
  }
}
