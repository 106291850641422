import React, { Component } from 'react'
import './index.scss'

const officialWebsite = [
  {
    name: '1inch',
    img: 'img_1inch.png',
    url: 'https://app.1inch.io',
  },
  {
    name: 'UniSwap',
    img: 'img_UniSwap.png',
    url: 'https://app.uniswap.org',
  },
  {
    name: 'SushiSwap',
    img: 'img_SushiSwap.png',
    url: 'https://app.sushi.com',
  },
]

export default class OfficialWebsite extends Component {
  render() {
    return (
      <div className='official_website'>
        {
          officialWebsite.map((item, index) => {
            return (
              <a key={index} href={item.url}>
                <div className='item'>
                  <img
                    className='logo'
                    src={require(`./assets/${item.img}`)}
                    alt='official website'
                  />
                  <div className='name'>{item.name}</div>
                </div>
              </a>
            )
          })
        }
      </div>
    )
  }
}
