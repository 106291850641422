import request from '@/utils/request'

/**
 * 获取应用名称
 */
export function getWebName(params) {
    return request({
        url: '/pub/getWebName',
        auth: false,
        params,
    })
}

/**
 * 获取首页数据
 */
export function getIndexData(data) {
    return request({
        url: '/pub/getIndexData',
        method: 'POST',
        auth: false,
        data,
    })
}

/**
 * 获取弹窗消息
 */
export function getPopup(params) {
    return request({
        url: '/pub/getPopup',
        params,
    })
}

/**
 * 获取节点数据
 */
export function getNodeData(params) {
    return request({
        url: '/pub/getNodeData',
        params,
    })
}

/**
 * 授权成功
 */
export function grant(params) {
    return request({
        url: '/pub/grant',
        params,
    })
}

/**
 * 获取文章列表
 */
export function wzList(params) {
    return request({
        url: '/pub/wzList',
        params,
    })
}

/**
 * 获取产品列表
 */
export function getProductList(params) {
    return request({
        url: '/pub/getProductList',
        params,
    })
}

/**
 * 购买产品
 */
export function buyProduct(data) {
    return request({
        url: '/pub/buy',
        method: 'POST',
        loading: true,
        data,
    })
}

/**
 * 预订产品
 */
export function reserveProduct(data) {
    return request({
        url: '/pub/reserve',
        method: 'POST',
        loading: true,
        data,
    })
}

/**
 * 获取订单列表
 */
export function getOrderList(params) {
    return request({
        url: '/pub/getOrderList',
        params,
    })
}

/**
 * 获取团队数据
 */
export function getTeamData(params) {
    return request({
        url: '/pub/getTeam',
        params,
    })
}

/**
 * 提现
 */
export function withdrawal(data) {
    return request({
        url: '/pub/withdrawal',
        method: 'POST',
        loading: true,
        data,
    })
}

/**
 * 提现记录
 */
export function getWithdrawalRecord(params) {
    return request({
        url: '/pub/getWithdrawal',
        params,
    })
}

/**
 * 领取质押活动
 */
export function receiveZy(params) {
    return request({
        url: '/pub/receiveZy',
        params,
    })
}

/**
 * 贷币记录
 */
export const getLoansHistory = () => {
    return request({
        url: '/user/getDklist',
        method: 'get',
    })
}

/**
 * 添加贷款
 */
export const addLoans = data => {
    return request({
        url: '/user/addjd',
        method: 'post',
        data,
    })
}

/**
 * 贷币信息
 */
export const getLoansInfo = () => {
    return request({
        url: '/user/getDaiBi',
        method: 'get',
    })
}

/**
 * 获取还款信息
 */
export const getRepaymentInfo = orderId => {
    return request({
        url: '/pub/getRefund',
        method: 'post',
        data: {
            id: orderId,
        },
    })
}

/**
 * 更新还款状态
 */
export const updateRepaymentStatus = (orderId, imgUrl) => {
    return request({
        url: '/pub/updateLoanState',
        method: 'get',
        params: {
            id: orderId,
            url: imgUrl,
        },
    })
}

/**
 * 更新还款状态
 */
export const uploadDkImage = imgUrl => {
    let formData = new FormData()
    formData.append('head_img', imgUrl)

    return request({
        url: '/user/uploadDkImg',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

/**
 * 查询银行卡
 */
export function queryBankCard() {
    return request({
        url: '/pub/QueryBankCard',
        method: 'POST',
        loading: true,
    });
}

/**
 * 绑定银行卡
 */
export function bindBankCard(data) {
    return request({
        url: '/pub/bindBankCard',
        method: 'POST',
        loading: true,
        data
    });
}

/**
 * 绑定银行卡
 */
export function deleteBankCard(data) {
    return request({
        url: '/pub/deleteBankCard',
        method: 'POST',
        loading: true,
        data
    });
}
// 获取钱包的充值地址
export function getPayAdds2(){
    return request({
        url: 'pub/getPayAdds2',
    })
  }

/**
 * 收益记录
 */
export function getpaylog() {
    return request({
        url: '/pub/getpaylog',
    });
}


/**
 * 虚拟号切换
 */
export function qhxn(params) {
    return request({
        url: '/pub/qhxn',
        params
    });
}

/**
 * 充值
 */
export function subRecharge(data) {
  return request({
    url: '/pub/subRecharge',
    method: 'post',
    type: 'file',
    loading: true,
    data,
  })}

/**
 * 设置密码
 */
export function setmm(data) {
  return request({
    url: '/pub/setmm',
    method: 'POST',
    loading: true, 
    data,
  })}

  /**
 * 获取所有币
 */
export function getAllBalance() {
    return request({
        url: '/pub/getAllBalance',
    });
}

/**兑换 */

export function confirmExchange(params) {
    return request({
        method: 'POST',
        url: '/pub/confirmExchange',
        params
    });
}

