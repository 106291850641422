import React, { Component } from 'react'
import './index.scss'
import RecordPopup from '../RecordPopup'
import RevenueRecordPopup from "../RevenueRecordPopup";
import {formatNumberWithCommas} from "../../../../utils/util";

export default class Balance extends Component {

  state = {
    // 记录弹窗
    showRecordPopup: false,
    showRevenueRecordPopup: false,  // 收益弹窗
  }

  /**
   * 显示弹窗
   */
   handleShowPopup = () => {
    this.setState({ showRecordPopup: true })
  }

  /**
   * 关闭弹窗
   */
  handleClosePopup = () => {
    this.setState({ showRecordPopup: false })
  }

  openRevenueRecordPopup = ()=> {
    this.setState({ showRevenueRecordPopup: true })
  }

  closeRevenueRecordPopup = ()=> {
    this.setState({ showRevenueRecordPopup: false })
  }

  render() {
    const { showRecordPopup, showRevenueRecordPopup } = this.state
    const { indexData } = this.props
    return (
      <>
        <div className='balance_wrap'>
          <div className='record-tab'>
            <div className='record' onClick={this.handleShowPopup}>
              <img src={require('./assets/icon-record.png')} className='icon-left'/>
              <span>Transaction Record</span>
              <img
                  className='icon'
                  src={require('./assets/icon_arrow_right.svg').default}
                  alt='arrow'
              />
            </div>
            <div className='split-line'></div>
            <div className='record' onClick={this.openRevenueRecordPopup}>
              <img src={require('./assets/icon-income.png')} className='icon-left'/>
              <span>Revenue Record</span>
              <img
                  className='icon'
                  src={require('./assets/icon_arrow_right.svg').default}
                  alt='arrow'
              />
            </div>
          </div>
          <div className='info'>
            <div className='balance'>
              <div className='name'>Available Balance(USDT)</div>
              <div className='value'>${formatNumberWithCommas(indexData.usdt.toFixed(2),2)}</div>
            </div>
            <div className='other'>
              <div className='item'>
                <div className='name'>Total revenue (USDT)</div>
                <div className='value'>{formatNumberWithCommas(indexData.income.toFixed(2),2)}</div>
              </div>
              <div className='item'>
                <div className='name'>Earnings 24h (USDT)</div>
                <div className='value'>{formatNumberWithCommas(indexData.income24.toFixed(2),2)}</div>
              </div>
            </div>
          </div>
        </div>
        <RecordPopup
          visible={showRecordPopup}
          onClose={this.handleClosePopup}
        />
        <RevenueRecordPopup
            visible={showRevenueRecordPopup}
            onClose={this.closeRevenueRecordPopup}
        />
      </>
    )
  }
}
