import { useState } from 'react'
import { uploadDkImage } from '@/api'
import { compressorImage } from '@/utils/util'
import { Toast } from 'react-vant'

import './index.scss'

export default function MUploader(props) {
    const { name, imgKey, uploadSuccess } = props
    const [remoteUrl, setRemoteUrl] = useState('')
    const [localUrl, setLocalUrl] = useState('')

    const submitUpload = async e => {
        try {
            console.log('e', e)
            const file = e.target.files[0]
            const img = await compressorImage(file, 'file')
            const { path, rpMsg } = await uploadDkImage(img)
            setRemoteUrl(path)
            setLocalUrl(URL.createObjectURL(img))
            Toast.success(rpMsg)
            uploadSuccess && uploadSuccess(name, imgKey, path)
        } catch (error) {
            e.target.value = ''
            console.log('error.message', error.message)
            Toast.fail(error.message)
        }
    }

    return (
        <div className='upload'>
            <img className='upload-img' src={localUrl || require('./assets/upload.png')} alt='upload' />
            <div className='sub-title'>{name}</div>
            <input type='file' accept='image/*' className='rv-uploader__input' onChange={submitUpload} />
        </div>
    )
}
