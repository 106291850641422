import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Toast } from 'react-vant'

import './index.scss'

export default class ShareInfo extends Component {
  render() {
    const { indexData, temData } = this.props
    return (
      <div className='share_info'>
        <div className='info'>
          <div className='title'>
            Get referral
            <span className='num'> {temData.win} </span>
            USDT rewards
          </div>
          <div className='content'>Invite friends to use Ai quantitative trading, you can get {temData.win}USDT reward</div>
        </div>
        <div className='link_wrap'>
          <span>{indexData.tgurl}</span>
          <CopyToClipboard
            text={indexData.tgurl}
            onCopy={() => Toast.info('Copy success')}
          >
            <span className='copy'>Copy</span>
          </CopyToClipboard>
        </div>
        <CopyToClipboard
          text={indexData.tgurl}
          onCopy={() => Toast.info('Copy success')}
        >
          <div className='share_bth'>SHARE LINK</div>
        </CopyToClipboard>
      </div>
    )
  }
}
