import React, { Component } from "react";
import moment from "moment-timezone";
import { Popup } from "react-vant";
import { baseURL } from "@/config";
import { getOrderList } from "@/api";
import { formatNumberWithCommas } from "@/utils/util.js";
import "./index.scss";

export default class OrderPopup extends Component {
  state = {
    // 订单列表
    orderList: [],
    // 预订
    yy: [],
  };

  /**
   * 获取 logo 列表
   * @param {Number} num 买入数量
   * @returns {Array}
   */
  getLogoList = (num) => {
    if (num < 5000) {
      return [1, 2, 3, 4];
    }
    if (num < 10000) {
      return [4, 2, 5, 6, 7];
    }
    return [3, 8, 9, 2, 10, 11, 12];
  };

  /**
   * 获取订单列表
   */
  getOrderList = async () => {
    const {
      data: { list, yy },
    } = await getOrderList();
    list.forEach((order) => {
      order.logo = order.logo
        ? baseURL + order.logo
        : require("./assets/icon_pro_usdt.svg").default;
      order.logoList = this.getLogoList(order.lnum);
    });
    yy.forEach((order) => {
      order.logo = order.logo
        ? baseURL + order.logo
        : require("./assets/icon_pro_usdt.svg").default;
      order.logoList = this.getLogoList(100000);
    });
    this.setState({ orderList: list, yy });
  };

  /**
   * 弹窗打开触发
   */
  onOpen = () => {
    // 获取订单列表
    this.getOrderList();
  };

  /**
   * 日期格式化
   */
  dateFormat = (value) => {
    const tz = moment.tz.guess();
    return moment(value * 1000)
      .tz(tz)
      .format("MMMM D, YYYY h:mm:ss A");
    // .format('YYYY-MM-DD HH:mm:ss')
  };

  /**
   * 获取百分比
   * @param {Number} a
   * @param {Number} b
   */
  getRatio = (a, b) => {
    const ratio = (a / b) * 100;
    if (ratio > 100) return 100;
    if (isNaN(ratio)) return 0;
    return parseFloat(ratio.toFixed(2));
  };

  render() {
    let { orderList, yy: yyList } = this.state;
    console.log(yyList);
    const { visible, onClose } = this.props;
    return (
      <Popup
        className="order_popup_container"
        visible={visible}
        onClickOverlay={onClose}
        position="right"
        onOpen={this.onOpen}
      >
        <div className="popup_header">
          <img
            className="left_back"
            src={require("./assets/icon_nav_back.svg").default}
            alt="back"
            onClick={onClose}
          />
          <span>Order List</span>
        </div>
        <div className="popup_content">
          {yyList.map((yy) => {
            return (
              <div className="order_item">
                <div className="item_info">
                  <div className="name">Order Type</div>
                  <div className="value">
                    <img className="icon_usdt" src={yy.logo} alt="usdt" />
                    <span className="type_name">{yy.name}</span>
                  </div>
                </div>
                <div className="item_info">
                  <div className="name">Configure amount</div>
                  <div className="value">
                    <span className="amount_num">
                      {formatNumberWithCommas(yy.f_num.toFixed(2), 2)}
                    </span>
                    <span className="amount_symbol">USDT</span>
                  </div>
                </div>
                <div className="item_info">
                  <div className="name">Order time</div>
                  <div className="value">{this.dateFormat(yy.ctime)}</div>
                </div>
                {yy.state > 0 && (
                  <div className="item_info">
                    <div className="name">Expire date</div>
                    <div className="value">{this.dateFormat(yy.etime)}</div>
                  </div>
                )}
                <div className="item_info">
                  <div className="name">Order ID</div>
                  <div className="value">{yy.id}</div>
                </div>
                <div className="item_info">
                  <div className="name">Order Status</div>
                  <div className="value">
                    {yy.state === 0 && (
                      <span className="order_status progress">Pending</span>
                    )}
                    {yy.state === 2 && (
                      <span className="order_status expired">Fail</span>
                    )}
                    {yy.state === 1 && (
                      <div className="progress-container">
                        <div className="progress-wrap">
                          <div className="item">
                            <span
                              style={{
                                width: `${this.getRatio(yy.w_num, yy.f_num)}%`,
                              }}
                            >
                              {this.getRatio(yy.w_num, yy.f_num)}%
                            </span>
                          </div>
                        </div>
                        <div className="label">
                          <div className="item">
                            <span>{yy.w_num}</span>/{yy.f_num}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {yy.win > 0 && (
                  <div className="item_info">
                    <div className="name">Appointment Rewards</div>
                    <div className="value">
                      <span className="amount_num">
                        {formatNumberWithCommas(yy.win.toFixed(2), 2)}
                      </span>
                      <span className="amount_symbol">USDT</span>
                    </div>
                  </div>
                )}
                <div className="item_logos">
                  {yy.logoList.map((logo) => {
                    return (
                      <img
                        key={logo}
                        className="logo_icon"
                        src={require(`@/assets/images/product_logo/${logo}.png`)}
                        alt="coin"
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
          {orderList.map((order) => {
            return (
              <div key={order.id} className="order_item">
                <div className="item_info">
                  <div className="name">Order Type</div>
                  <div className="value">
                    <img className="icon_usdt" src={order.logo} alt="usdt" />
                    <span className="type_name">{order.name}</span>
                  </div>
                </div>
                <div className="item_info">
                  <div className="name">Escrow amount</div>
                  <div className="value">
                    <span className="amount_num">
                      {formatNumberWithCommas(order.lnum.toFixed(2), 2)}
                    </span>
                    <span className="amount_symbol">USDT</span>
                  </div>
                </div>
                <div className="item_info">
                  <div className="name">Profit</div>
                  <div className="value">
                    <span className="amount_num">
                      {formatNumberWithCommas(order.income.toFixed(2), 2)}
                    </span>
                    <span className="amount_symbol">USDT</span>
                  </div>
                </div>
                <div className="item_info">
                  <div className="name">Order time</div>
                  <div className="value">{this.dateFormat(order.ctime)}</div>
                </div>
                <div className="item_info">
                  <div className="name">Expire date</div>
                  <div className="value">{this.dateFormat(order.ltime)}</div>
                </div>
                <div className="item_info">
                  <div className="name">Order ID</div>
                  <div className="value">{order.id}</div>
                </div>
                <div className="item_info">
                  <div className="name">Order Status</div>
                  <div className="value">
                    {order.state === 0 && (
                      <span className="order_status progress">Pending</span>
                    )}
                    {order.state === 1 && (
                      <span className="order_status progress">in progress</span>
                    )}
                    {order.state === 2 && (
                      <span className="order_status expired">Expired</span>
                    )}
                    {order.state === 3 && (
                      <span className="order_status expired">Fail</span>
                    )}
                  </div>
                </div>
                <div className="item_logos">
                  {order.logoList.map((logo) => {
                    return (
                      <img
                        key={logo}
                        className="logo_icon"
                        src={require(`@/assets/images/product_logo/${logo}.png`)}
                        alt="coin"
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Popup>
    );
  }
}
